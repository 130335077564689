<template>
    <div
        class="content-loading-sidebar-sending"
        style="background: #ffffff;"
      >
        <Circle2 class="spinner-social-sidebar-sending" />
      </div>
</template>

<script>
export default {
  name: 'Loading',
  components: {
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  }
}
</script>

<style lang='scss' scoped>

.content-loading-sidebar-sending {
    z-index: 61;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-social-sidebar-sending {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }
</style>
