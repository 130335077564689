import { callApiService } from '@/_services'
const API_URL = process.env.VUE_APP_URL

export const chatService = {
  createChat,
  deleteChat,
  sendMessage,
  getChatsList,
  getMessagesList,
  getMessageClassification,
  sendFeedback,
  getQuestionsDefault,
  chosenTemplateDefault
}

async function chosenTemplateDefault (id) {
  if (!id || (id && typeof id !== 'string')) return

  return callApiService.callApi(`${API_URL}intelligence/templates/${id}/use`, 'PATCH')
}

async function getQuestionsDefault () {
  return callApiService.callApi(`${API_URL}intelligence/templates`, 'get')
}

async function getChatsList () {
  return callApiService.callApi(`${API_URL}intelligence/chats`, 'get')
}

async function getMessagesList (chatId) {
  return callApiService.callApi(`${API_URL}intelligence/${chatId}/messages`, 'get')
}

async function getMessageClassification (body) {
  return callApiService.callApi(`${API_URL}intelligence/classify`, 'post', body)
}

async function deleteChat (chatId) {
  const url = `${API_URL}intelligence/${chatId}/chats`
  return await callApiService.callApi(url, 'delete')
}

async function sendFeedback (messageId, body) {
  const url = `${API_URL}intelligence/${messageId}/feedback`
  return await callApiService.callApi(url, 'post', body)
}

async function sendMessage (body, resolve, reject) {
  const token = JSON.parse(localStorage.getItem('bindsUser'))
  const response = await fetch(`${API_URL}intelligence/${body._id}/message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  })

  if (!response.body) {
    throw new Error('Readable stream not supported')
  }

  const reader = response.body.getReader()
  const decoder = new TextDecoder()

  let dataBuffer = ''

  // Read the stream chunk by chunk
  while (true) {
    const { done, value } = await reader.read()
    if (done) break

    // Decode the chunk and add it to the buffer
    dataBuffer += decoder.decode(value, { stream: true })

    // Process each line in the data buffer as it's received
    const lines = dataBuffer.split('\n')

    lines.forEach((line, index) => {
      if (line.startsWith('data: ')) {
        try {
          const json = JSON.parse(line.replace('data: ', '').trim())
          return resolve(json) // Trigger the callback function with parsed JSON data
        } catch (err) {
          console.error('Failed to parse line:', line)
          return reject(err)
        }
      }

      // If this is the last line, keep it in the buffer; otherwise, reset it
      if (index === lines.length - 1) {
        dataBuffer = line
      } else {
        dataBuffer = ''
      }
    })
  }
}

async function createChat (body) {
  return callApiService.callApi(`${API_URL}intelligence/chats`, 'post', body)
}
