<template>
  <div class="container-chat-list">
    <!-- Header  -->
    <div class="chat-header">
      <p class="title-chat-header">{{ $t('intelligence.title') }}</p>

      <div class="icons-chat-list">
        <i class="material-icons-outlined icon-show-list-side"
          v-title="$t('intelligence.tooltips.chat_view')"
          title-placement="top"
          @click="toggleChatList()"
        >vertical_split</i>
        <i class="material-symbols-outlined icon-new-chat"
          v-if="!intelligence.showNewChat"
          v-title="$t('intelligence.tooltips.start_chat')"
          title-placement="top"
          @click="toggleNewChat(); setKey('currentChat', null)"
          >edit_square</i>
      </div>
    </div>

    <!-- Chat List -->
    <div class='chat-grouped-list' v-if="intelligence.chats.length">
      <div v-for="chat in intelligence.chats" :key="chat.conversationId" class="chat-item"
        :class="{ 'active': intelligence.currentChat?._id === chat?._id }">
        <div class="chat-info" @click="setCurrentChat(chat)">
          <div class="title" v-title="chat.title" title-placement="left">
            {{ chat.title }}
          </div>

          <!-- More options icon (more_vert) -->
          <i v-if="chat._id" v-title='$t("intelligence.tooltips.more_options")' title-placement="top" class="material-icons-outlined"
            @click="toggleSubMenu(chat, $event)">
            more_vert
          </i>
        </div>
      </div>

      <!-- Submenu -->
      <div v-if="submenu.visible" class="submenu" :style="submenu.style">
        <button @click="deleteChat(submenu.chat)" v-title='$t("intelligence.tooltips.delete_alert")'
          title-placement='right'>
          <i class="material-icons-outlined">delete</i>
          {{ $t("intelligence.delete") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { chatService } from '@/_services'
import intelligenceMixin from '@/_mixins/intelligence'

export default {
  name: 'chatList',
  mixins: [intelligenceMixin],
  data () {
    return {
      imageDefault: '',
      showLoading: false,
      submenu: {
        visible: false,
        chat: null,
        style: {}
      }
    }
  },
  async mounted () {
    await this.getChatList()
    if (!this.intelligence.currentChat) {
      this.setKey('showNewChat', true)
      if (!this.intelligence.chats.length) {
        this.setChatDefault()
      }
    }

    this.$root.$on('update-chat-list', () => {
      this.getChatList()
    })
    // Add event listener to detect clicks outside
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy () {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.handleClickOutside)
    this.$root.$off('update-chat-list')
  },
  methods: {
    async getChatList () {
      this.$root.$emit('set-loading-intelligence-chat', true)
      let chats = await chatService.getChatsList()
      this.$root.$emit('set-loading-intelligence-chat', false)
      if (chats.length) {
        chats = chats?.map(chat => {
          if (!chat?.lastMessage) return null

          return {
            ...chat.lastMessage,
            title: chat.title || '',
            message: chat.lastMessage?.answer || this.$t('intelligence.response_not_found'),
            profilePic: this.imageDefault,
            time: this.$moment(chat?.createdAt).format('lll'),
            _id: chat._id
          }
        }).filter(Boolean)
      }
      this.setKey('chats', chats)
      if (!this.intelligence.currentChat && chats.length) {
        this.setCurrentChat(this.intelligence.chats[0])
      }
    },
    // Toggle the visibility of the submenu
    toggleSubMenu (chat, event) {
      event.stopPropagation()

      const rect = event.target.getBoundingClientRect()
      this.submenu = {
        visible: true,
        chat,
        style: {
          position: 'fixed',
          top: `${rect.top}px`,
          left: `${rect.left}px`,
          background: 'white',
          border: '1px solid #ddd',
          borderRadius: '4px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
          padding: '5px',
          width: '150px'
        }
      }
    },

    // Close menu when clicking outside
    handleClickOutside (event) {
      const isClickInside = event.target.closest('.submenu, .material-icons-outlined')
      if (!isClickInside) {
        this.submenu.visible = false
      }
    },

    // Handle delete action for the chat
    deleteChat (chat) {
      this.$root.$emit('set-loading-intelligence-chat', true)
      this.submenu.visible = false
      chatService
        .deleteChat(chat._id)
        .catch((error) => console.error('Error deleting chat:', error))
        .finally(async () => {
          await this.getChatList()
          this.$root.$emit('set-loading-intelligence-chat', false)

          if (this.intelligence.currentChat?._id === chat._id) {
            const newChat = this.intelligence.chats.length ? this.intelligence.chats[0] : null
            this.setKey('currentChat', newChat)
          }
          if (!this.intelligence.chats.length) {
            this.setChatDefault()
            this.setKey('showNewChat', true)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-toggle {
  color: #333;
  padding: 6px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  position: absolute;
  z-index: 61;
  top: 6px;
  left: 6px;
}

.accordion-toggle:hover {
  background-color: #f5f5f5;
}

.container-chat-list {
  height: 100vh;
  width: 30%;
  transition: width 0.5s, visibility 0.1s;
  position: relative;
  left: 0;
  top: 0;
  gap: 13px;
}

.chat-grouped-list {
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  scrollbar-color: #eeeeee white;
  align-items: center;
  margin: 2vw 0px;
}

.chat-header {
  display: flex;
  width: 100%;
  height: 5%;
  justify-content: space-around;
  align-items: center;
  color: #3f3356;

  .icons-chat-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    .icon-new-chat {
      cursor: pointer;
      font-size: 1.1vw;
      padding: 0.35vw;
      border-radius: 5px;
    }

    .icon-show-list-side {
      cursor: pointer;
      font-size: 1.4vw;
      padding: 0.35vw;
      border-radius: 5px;
    }

    .icon-new-chat:hover, .icon-show-list-side:hover {
      background: #3f335620;
    }
  }

  .title-chat-header {
    font-size: 1vw;
    font-weight: 600;
  }
}

.chat-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  padding: 8px;
  width: 90%;
}

.chat-item:hover,
.active {
  background-color: #eaf3ff;
}

.chat-info {
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;

  i {
    font-size: 1.3vw;
    font-weight: lighter;
    color: #949494;
    cursor: pointer;
  }
}

.title {
  font-size: 0.9vw;
  cursor: pointer;
  color: #333;
  padding: 5px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-preview {
  font-size: 14px;
  color: #888;
  width: 82%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.submenu {
  position: absolute;
  top: 100%;
  right: -10vw;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 10px;
  /* Set the width of the submenu */
}

.submenu button {
  width: 100%;
  padding: 3px;
  border-radius: 8px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;

  i {
    font-size: 1.2vw;
  }
}

.submenu button:hover {
  // background-color: #eaf3ff;
}
</style>
