import { orderFormater, objectToString } from '@/_helpers'
import marked from 'marked'
import DOMPurify from 'dompurify'
import { chatService, surveyService } from '@/_services'
import { mapGetters } from 'vuex'
import gsap from 'gsap'

export default {
  data () {
    return {
      messages: [],
      currentSurvey: {},
      currentQuestion: {},
      surveys: []
    }
  },
  computed: {
    ...mapGetters({
      accountIntelligence: 'account/getAccount',
      user: 'account/getUser',
      sendingFromNotification: 'providers/getSendingOnQuery',
      intelligence: 'providers/getIntelligence'
    }),
    canSeeIntelligence () {
      if (!this.accountIntelligence?.features?.intelligence) return false

      if (this.user.type === 'user') {
        return this.user.features?.intelligence
      }

      return true
    },
    messageCount () {
      return this.messages.length
    }
  },
  watch: {
    messageCount (newLength, oldLength) {
      if (newLength > oldLength && this.intelligence.isSideBarOpen) {
        this.$nextTick(async () => {
          await new Promise(resolve => setTimeout(resolve, 300)) // wait for the message to be rendered
          this.scrollToBottom()
        })
      }
    }
  },
  methods: {
    // New method to scroll to the bottom of chat messages
    scrollToBottom () {
      const chatMessagesEl = this.$refs.chatMessages
      if (chatMessagesEl) {
        chatMessagesEl.scrollTo({
          top: chatMessagesEl.scrollHeight,
          behavior: 'smooth'
        })
      }
    },
    updateChatListState (container, isOpened) {
      container.style.width = isOpened ? '30%' : '0%'

      if (isOpened) {
        setTimeout(() => {
          container.style.visibility = 'visible'
        }, 300)
      } else {
        container.style.visibility = 'hidden'
      }
    },
    toggleChatList () {
      const chatListOpened = !this.intelligence.showChatList
      const containerChatList = document.querySelector('.container-chat-list')

      this.updateChatListState(containerChatList, chatListOpened)

      this.$store.commit('providers/UPDATE_PROVIDER', {
        key: 'intelligence.showChatList',
        value: chatListOpened
      })
    },
    toggleNewChat () {
      this.$store.commit('providers/UPDATE_PROVIDER', { key: 'intelligence.showNewChat', value: !this.intelligence.showNewChat })
    },
    toggleSidebar (isToOpen) {
      const containerSidebar = document.querySelector('.container-sidebar-chat')
      if (!containerSidebar) return
      this.setKey('isSideBarOpen', !this.intelligence.isSideBarOpen)
      const sidebarWidth = isToOpen ? '50vw' : '0vw'
      if (!isToOpen) {
        this.$store.commit('providers/RESET_PROVIDER_DEFAULT', 'intelligence')
      }
      gsap.to(containerSidebar, {
        width: sidebarWidth,
        duration: 0.3
      })
    },
    setKey (key, value) {
      this.$store.commit('providers/UPDATE_PROVIDER', { key: `intelligence.${key}`, value })
    },
    setChatDefault () {
      this.$store.commit('providers/SET_INITIAL_CHAT')
    },
    hideChatDefault () {
      this.$store.commit('providers/HIDE_INITIAL_CHAT')
    },
    setCurrentChat (chat) {
      if (!chat._id) return

      this.setKey('currentChat', chat)
      this.setKey('showNewChat', false)
    },
    convertToHtml (text) {
      try {
        const rawHtml = marked(text)
        const cleanHtml = DOMPurify.sanitize(rawHtml)
        return cleanHtml
      } catch (error) {
        console.error('Error converting message:', error)
      }
    },
    async getSurvey (id) {
      return surveyService.getSurvey(id)
    },
    async getSurveys () {
      const getSurveys = await surveyService.getAllSurveys()
      if (getSurveys.length) {
        getSurveys.sort((a, b) => (a.title?.toUpperCase() > b.title?.toUpperCase() ? 1 : -1))
      }
      this.surveys = orderFormater.expiredAt(getSurveys)
    },
    randomColor () {
      const red = Math.floor(Math.random() * 156) + 100 // Range 100-255
      const green = Math.floor(Math.random() * 156) + 100 // Range 100-255
      const blue = Math.floor(Math.random() * 156) + 100 // Range 100-255
      const color = `rgba(${red}, ${green}, ${blue}, 0.8)` // Add alpha for transparency
      return color
    },
    async sendMessage (chatData) {
      const messageToSend = {
        _id: chatData._id,
        startDate: chatData.startDate,
        endDate: chatData.endDate,
        survey: chatData.survey,
        query: objectToString(chatData.query),
        questionId: chatData.questionId,
        question: chatData.question,
        metadata: chatData.metadata
      }

      const response = await new Promise((resolve, reject) => chatService.sendMessage(messageToSend, resolve, reject))

      if (response.answer) {
        const answer = {
          htmlText: this.convertToHtml(response.answer),
          sender: 'AI Bot',
          type: 'received',
          pureText: response.answer,
          helpful: null,
          showOptions: false,
          intent: response.intent,
          startDate: response.startDate,
          endDate: response.endDate,
          survey: response.survey,
          query: response.query
        }

        const question = {
          htmlText: this.convertToHtml(chatData.question),
          timestamp: '',
          sender: 'You',
          type: 'sent'
        }

        return {
          question,
          answer
        }
      }
      return {}
    }
  }
}
