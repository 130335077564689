/**
 * Provider Data Store Module
 *
 * This Vuex module is used to store the provider data.
 * In Vue 3, we have the logic to share data between components without considering the parent-child relationship.
 * However, in this module, we are using Vuex store to store the provider data to facilitate sharing between components
 * and ensure data consistency.
 *
 * Note:
 *   Before using this module, ensure that you update the data only from this module and only from one place.
 */
import { set } from 'lodash-es'
import i18n from '../../translations/i18n'

const defaultState = () => {
  return {
    sendingIdOnQuery: '',
    intelligence: defaultIntelligence()
  }
}

const defaultIntelligence = () => {
  return {
    isSideBarOpen: false,
    showChatList: true,
    showNewChat: false,
    currentChat: null,
    chats: []
  }
}

const initialChat = () => {
  return {
    _id: '',
    title: i18n.t('intelligence.new_chat'),
    question: '',
    survey: '',
    startDate: '',
    endDate: '',
    query: { $and: [] }
  }
}

const state = defaultState()

const mutations = {
  /**
     * Updates the provider data with the given object.
     * @param {*} state
     * @param {{key: '', value: ''}} objectToStore
     */
  UPDATE_PROVIDER (state, objectToStore) {
    set(state, objectToStore.key, objectToStore.value)
  },
  /**
   * Resets the provider data for the specified key.
   * @param {*} state
   * @param {string} key
   */
  RESET_PROVIDER (state, key) {
    state[key] = ''
  },
  RESET_PROVIDER_DEFAULT (_state, key) {
    state[key] = defaultState()[key]
  },
  SET_INITIAL_CHAT (_state, _key) {
    state.intelligence.chats = [initialChat()]
  },
  HIDE_INITIAL_CHAT (state, _key) {
    const initialChatIndex = state.intelligence.chats.findIndex(chat => !chat._id)

    if (initialChatIndex < 0) return

    state.intelligence.chats.splice(initialChatIndex, 1)
  }
}

const getters = {
  getSendingOnQuery: state => state.sendingIdOnQuery,
  getIntelligence: state => state.intelligence
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
