export default {
  Dashboard: 'Dashboard',
  Home: 'Home',
  activity: {
    interface: {
      header: {
        name: 'Nome',
        email: 'Email',
        origin: 'Origem',
        action: 'Ação',
        date: 'Data'
      },
      users: 'Usuarios',
      select: 'selecionar',
      actions_label: 'Ações',
      actions: {
        created: 'Criou',
        updated: 'Alterou',
        deleted: 'Excluiu',
        sent: 'Enviou',
        expired: 'Expirada',
        unexpired: 'Não expirada',
        added: 'Adicionou',
        removed: 'Removeu'
      },
      all: 'Todos',
      entities: {
        annotation: 'Anotação',
        account: 'Conta',
        user: 'Usuário',
        survey: 'Pesquisa',
        message: 'Mensagem',
        scheduled: 'Agendamento',
        ResendSending: 'Reenvio',
        collector: 'Collector',
        ticketCategory: 'Categoria do Ticket',
        emailTest: 'Teste de Email',
        role: 'Grupo',
        token: 'Token'
      }
    },
    interpolate: {
      resend: 'um reenvio',
      tag: 'uma tag',
      annotation: 'uma anotação',
      user: 'o usuário',
      survey: 'a pesquisa',
      ticket: 'um atendimento',
      role: 'um grupo',
      message: 'uma mensagem',
      category: 'a categoria',
      account: 'a conta',
      seedBatch: 'um lote',
      scheduled: 'um agendamento'
    },
    filter_tooltip_today: 'Filtrar por hoje',
    action: 'Ação',
    displaying: 'Exibindo',
    of_today: 'de hoje',
    from_yesterday: 'de ontem',
    sending_expired: 'Abordagem expirada',
    sending_unexpired: 'Abordagem ativada',
    sending: 'uma abordagem',
    annotation: 'Anotação',
    has_expired: 'Expirou',
    has_unexpired: 'Ativou',
    has_created: 'Criou',
    has_added: 'Adicionou',
    has_removed: 'Removeu',
    has_deleted: 'Excluiu',
    has_sent: 'Enviou',
    has_updated: 'Alterou',
    user_updated: 'Usuário alterado',
    user_created: 'Usuário criado',
    user_deleted: 'Usuário deletado',
    role_updated: 'Grupo alterado',
    role_created: 'Grupo criado',
    role_deleted: 'Grupo deletado',
    survey_updated: 'Pesquisa alterada',
    survey_created: 'Pesquisa criada',
    survey_deleted: 'Pesquisa deletada',
    message_updated: 'Mensagem alterada',
    message_created: 'Mensagem criada',
    message_deleted: 'Mensagem deletada',
    tag_updated: 'Tag alterada',
    tag_created: 'Tag criada',
    tag_deleted: 'Tag deletada',
    tag_error_add: 'Erro ao adicionar tag',
    survey_send: 'Pesquisa Enviada',
    seedBatch_sent: 'Lote enviado',
    title: 'Atividades',
    label: 'atividades',
    ticket: 'Atendimento',
    seedBatch: 'Lote',
    no_activity: 'Ainda sem atividades!',
    ticket_updated: 'Atendimento alterado',
    ticket_created: 'Atendimento criado',
    ticket_deleted: 'Atendimento deletado',
    addTag_created: 'Tag adicionada',
    removeTag_deleted: 'Tag removida',
    addTag: 'Adicionou tag',
    removeTag: 'Removeu tag',
    ticketCategory_updated: 'Categoria alterada',
    ticketCategory_created: 'Categoria criada',
    ticketCategory_deleted: 'Categoria deletada',
    annotation_updated: 'Anotação alterada',
    annotation_created: 'Anotação criada',
    annotation_deleted: 'Anotação deletada',
    account_updated: 'Conta alterada',
    account_created: 'Conta criada',
    account_deleted: 'Conta deletada',
    BrandOnBindsVoiceScore_created: 'Marca criada',
    BrandOnBindsVoiceScore_added: 'Marca adicionada',
    BrandOnBindsVoiceScore_removed: 'Marca removida',
    ResendSending_created: 'Reenvio criado',
    Scheduled_deleted: 'Agendamento cancelado',
    yesterday: 'Ontem',
    today: 'Hoje',
    tomorrow: 'Amanhã',
    last: 'Última',
    when: 'Quando',
    time: 'Hora',
    no_logs: 'Não foram encotrados atividades, reveja os filtros',
    no_logs_day: 'Não foram encotrados atividades, reveja os filtros'
  },
  Sendings: 'Abordagens',
  sendings: {
    pick_older_date: 'Desde',
    sendings: 'abordagens',
    deliveries: 'entregas',
    delivery_rate: 'taxa de entrega',
    openings: 'aberturas',
    clicks: 'cliques',
    responses: 'Respostas',
    response_rate: 'Taxa de resposta',
    softBounces: 'devoluções temporárias',
    bounces: 'devoluções',
    spam: 'queixas de SPAM',
    without_sendings: 'Ops! Ainda não temos nenhuma abordagem para te mostrar.',
    deleted: 'Abordagem deletada!',
    error_deleted: 'Erro ao deletar abordagem',
    archived: 'Abordagem arquivada!',
    resolved: 'Resolvido'
  },
  'bar-filter': {
    older_date: 'Desde older date',
    status: 'Status'
  },
  annotation: {
    annotation: 'Anotações',
    description: 'Crie notas sobre as especificações das respostas e anote informações sobre o atendimento.',
    go_top: 'Ir para o topo',
    notes: 'Notas',
    max_length: 'Máximo de carácteres',
    yes: 'Sim',
    no: 'Não',
    delete_annotation: 'Deseja excluir está anotação ?',
    create: 'Criar anotações',
    type_note: 'Escreva a Anotação',
    delete: 'Deletar'
  },
  account_details: {
    title: 'Configurações da conta',
    subtitle: 'Configurações Gerais',
    default: 'Padrão',
    account_name: 'Nome da conta',
    type_id_or_name: 'Digite o ID ou nome da conta',
    type_url: 'Digite a URL',
    updated: 'Atualizada!',
    updating_error: 'Erro ao tentar atualizar',
    enter_account: 'Entrar nesta conta',
    no_name: 'Sem nome',
    no_email: 'Sem e-mail',
    no_phone: 'Sem telefone',
    already_sent: 'Já enviou pesquisa',
    didnt_send_yet: 'Ainda não enviou pesquisa',
    account: {
      name: {
        value: 'Nome',
        description: ' O nome da conta é usado como nome do remetente, nas mensagens de e-mail'
      },
      url: {
        value: 'Url',
        description: ' A url para o website da conta'
      },
      dashboardPeriod: {
        value: 'Data padrão para os filtros',
        description: 'Utilizaremos este campo para configurar o tempo padrão nos filtros de data através da plataforma'
      }
    },
    send_preferences: {
      title: 'Configurações de Envio',
      name: {
        value: 'Campo do Nome',
        description: 'Este é o campo, na integração, que contém o nome do destinatário'
      },
      email: {
        value: 'Campo do Email',
        description: 'Este é o campo, na integração, que contém o email do destinatário'
      },
      phone: {
        value: 'Campo do Telefone',
        description: 'Este é o campo, na integração, que contém o telefone do destinatário'
      },
      sending_interval: {
        value: 'Intervalo de envio',
        description: 'Impede o envio para um mesmo destinatário durante o intervalo de tempo',
        ask: 'Intervalo por pesquisa?'
      },
      expiration_time: {
        value: 'Tempo de expiração da pesquisa',
        description: 'Define o limite de tempo máximo para uma pesquisa a ser respondida após o envio'
      }
    },
    features: {
      sms_enabled: 'Abordagens por SMS',
      sms_metadata: 'Habilitar envio de SMS com Metadados',
      tickets: 'Atendimentos (close the loop)',
      edit_survey_response: 'Editar pesquisa respondida',
      associate_notes: 'Atribuir anotações ao atendimento',
      sheet_export: 'Exportação de arquivos por email',
      has_invoice: 'Cliente pagante (liberar trava dos disparos)',
      require_category: 'Obrigar categorização do atendimento',
      text_analytics: 'Análise de texto',
      comparative_dash: 'Relatório de performance',
      tags: 'Tags',
      two_way_auth: 'Autenticação em dois fatores',
      v2: 'Habilitar transição do sistema da Binds (v1/v2)',
      allow_to_v2: 'Exibir o botão de transição para a v2',
      allow_to_v2_description: 'O botão só irá aparecer para o dono da conta',
      confirm_optout_title: 'Tela para confirmar cancelamento de inscrição de email',
      confirm_optout_description: 'Ao clicar para não receber mais emails da empresa, será exibida uma tela para que se confirme essa ação.',
      binds_voice_score: 'Habilitar o Binds Voice Score',
      binds_voice_score_description: 'Dashboard que analisa textos baseado em fontes de dados como redes sociais e planilhas.',
      intelligence: 'Binds Intelligence',
      intelligence_description: 'Chat de conversação com inteligência artificial',
      whatsapp: {
        title: 'Habilitar WhatsApp',
        phone_id: 'Identificação do número de telefone',
        business_id: 'Identificação da conta do WhatsApp Business',
        token: 'Token de acesso permanente',
        limit_per_day: 'Limite de envio por dia',
        limit_per_day_label: 'Quantidade de envios por dia',
        send_interval_title: 'Intervalo de envio por telefone',
        send_interval_description: 'Impede o envio para um mesmo destinatário durante o intervalo de tempo. Utilizar um período mais longo melhora a experiência do usuário e aumenta a chance de resposta.',
        template_text_simple_title: 'Mensagem de texto simples',
        template_text_simple_description: 'template do tipo texto',
        template_text_button_title: 'Mensagem com botão',
        template_text_button_description: 'template do tipo texto + botão',
        template_text_button_image_title: 'Mensagem com botão e imagem',
        template_text_button_image_description: 'template do tipo texto + botão + imagem'
      },
      sso: {
        title: 'Habilitar o SSO',
        client_id: 'Especifica a ID do cliente do aplicativo que está registrado no Azure Active Directory',
        client_secret: 'Segredo usado para estabelecer a propriedade do ID do cliente',
        tenant: 'Identificador do locatário do Azure AD'
      }
    }
  },
  date_filter: {
    from: 'De',
    to: 'Até',
    empty: 'Selecione uma data',
    periods: {
      day: '24 HORAS',
      week: '7 DIAS',
      month: '30 DIAS',
      semester: '6 MESES',
      year: '12 MESES'
    }
  },
  login: {
    welcome: 'Bem-vindo',
    password: 'Senha',
    back: 'Voltar',
    enter: 'Entrar',
    send: 'Enviar',
    reset: 'Esqueci a senha',
    new_at_binds: 'novo na Binds.co?',
    new_account: 'Cadastre-se',
    login_error: 'Login ou senha incorretos',
    complete_solution: 'A solução completa para atendimento e avaliação',
    custon_indicators: 'Indicadores personalizados',
    beyond_NPS: 'Além do NPS',
    managers_notifications: 'Notificações para gestores',
    in_depth_reports: 'Relatórios completos',
    easy_integrations: 'Fácil integração',
    language_select: 'Selecionar idioma',
    alert: 'Aviso Importante!',
    alert_v1_redirect: 'Essa conta está utilizando a nossa antiga versão da plataforma, clique em "Redirecionar" para acessar o local correto.',
    redirect: 'Redirecionar',
    ready: 'Tudo pronto para você começar. Faça login!'
  },
  signup: {
    form_title: 'Crie sua conta em segundos',
    company: 'Nome da empresa',
    phone: 'Telefone',
    email: 'E-mail',
    password: 'Senha',
    name: 'Nome',
    enter: 'Entrar',
    create_account: 'Criar conta',
    have_account: 'Já tem uma conta?',
    agreement: 'Ao clicar no botão, você está concordando com nossos',
    use_terms: 'Termos de Uso',
    incorrect_field_value: 'Preencha este campo com um valor válido',
    congratulation: 'Parabéns, sua conta foi criada! Enviamos um email para definir sua senha.'
  },
  resetpass: {
    title: 'Nova senha para',
    pass1: 'Nova senha',
    pass2: 'Confirme a nova senha',
    email_sent: 'Enviamos a senha para o seu e-mail',
    fill_in: 'Preencha os campos corretamente.',
    code_expired: 'O tempo para alterar a senha expirou. Por favor, solicite uma nova.',
    save: 'Salvar'
  },
  metadata: {
    title_resend_metadata: 'Reenvio',
    manageFields: 'Gerenciar campos personalizados',
    title: 'Campos personalizados',
    description: 'Administre os campos personalizados da sua conta',
    text_type: 'Texto',
    field: 'Campo',
    label: 'Rótulo',
    type: 'Tipo',
    groupable: 'Agrupável',
    enable_insight: 'Insights',
    error_enable_insight: 'Você só pode ter 3 campos com insights ativo',
    ranking: 'Exemplo: Ao criar um campo personalizado chamado "cidade" e configurá-lo como agrupável, você poderá ver um ranking de respostas por "cidade" caso você tenha feito o uso deste campo ao disparar sua pesquisa por email/telefone.',
    new: 'Novo campo personalizado',
    add_field: 'Adicionar campo',
    error_add: 'Já existe um campo com este nome'
  },
  tickets: {
    description: 'Feche o ciclo de atendimento com o cliente com tratativas via e-mail.',
    open_onsurvey: 'Abertura de atendimento automática',
    no_ticket: 'Nenhum atendimento selecionado.',
    first_contact: 'Primeira tentativa de contato',
    attendants_history: 'Histórico de Atendentes',
    select_survey: 'Selecione a pesquisa',
    category: {
      send_now: 'Agora',
      send_24h: '24 Horas',
      send_7d: '7 Dias',
      send_30d: '30 Dias',
      head_title: 'Categoria',
      add: 'Adicionar Categoria',
      new_category: 'Nome',
      created: 'Categoria Criada',
      created_error: 'Alguma coisa esta errada =(',
      deleted: 'Categoria deletada',
      to_edit: 'Editar',
      select: 'Selecionar',
      add_sub: 'Sub Categoria',
      edited_success: 'Categoria editada',
      no_category: 'Sem categoria'
    },
    title: 'Atendimento',
    questions: 'Perguntas',
    select_answer: 'Selecione um resposta',
    no_message: 'Nenhuma mensagem',
    error_create_auto_message: 'Título e corpo da mensagem são obrigatórios.',
    error_delete_msg: 'Não foi possivél apagar a mensagem.',
    no_categories: 'Nenhuma categoria',
    no_attendant: 'Nenhum atendente',
    attendant: 'Atendente',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    filled: 'Preenchido',
    unfilled: 'Não preenchido',
    create: 'Criar atendimento',
    choose_attendant: 'Escolha o atendente',
    new_ticket: 'Novo atendimento',
    success_msg: 'Atendimento criado!',
    error_msg: 'Erro ao criar atendimento',
    by: 'Por',
    closed: 'Atendimento encerrado',
    opened: 'Atendimento aberto',
    history: 'Histórico de atendentes',
    see_ticket: 'Ver atendimento',
    all_categories: 'Categorias',
    no_items: 'Não há itens para mostrar',
    no_more: 'Não há mais itens',
    load_more: 'Carregar mais',
    status: {
      all: 'Todos status',
      opened: 'Abertos',
      assigned: 'Atribuídos',
      unassigned: 'Não atribuídos',
      closed: 'Fechados'
    },
    search: 'Pesquise por nome ou e-mail',
    any_attendant: 'Qualquer atendente',
    close_ticket: 'Fechar atendimento',
    open_ticket: 'Abrir atendimento',
    confirm_action_text: 'Tem certeza que deseja fechar esse atendimento?',
    confirm_action: 'Confirmar ação',
    select_category: 'Selecionar categoria',
    change_attendant: 'Trocar atendente',
    change_category: 'Trocar categoria',
    transfer: 'Transferir',
    last: 'Último status',
    events: {
      created: 'atendimento criado',
      transfered: 'atribuiu este atendimento para',
      opened: 'abriu este atendimento',
      closed: 'fechou este atendimento',
      categorized: 'categorizou este atendimento para',
      no_category: 'categorizou este atendimento para: Sem categoria'
    }
  },
  profile: {
    title: 'Meu Perfil',
    change_pass: 'Alterar senha',
    name: 'Nome:',
    email: 'E-mail:',
    phone: 'Telefone:',
    password: 'Senha:',
    confirm: 'Confirme a senha:',
    wrong_pass: 'As senhas estão diferentes.',
    sms: 'Aceito receber alertas por SMS definidos pelo gestor',
    news: 'Me avise sobre novidades e funcionalidades da plataforma',
    save: 'Salvar',
    success: 'Sucesso!',
    error: 'Algo deu errado. Confira os dados.',
    delete_metadata: 'Você realmente deseja excluir este campo personalizado?',
    smsAlert: 'Alerta de SMS não ativado. Informe seu número de telefone',
    personalInfo: 'Informações pessoais',
    otherInfo: 'Outras informações'
  },
  sendConfig: {
    success: 'Sucesso!',
    error: 'Ops! Ocorreu um erro inesperado.',
    seconds: 'Segundos',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Dias',
    weeks: 'Semanas',
    months: 'Meses',
    interval_title: 'Configurações de envio',
    period: 'Período',
    interval_subtitle: 'Bloqueio de envio',
    expiration_title: 'Tempo de expiração da pesquisa',
    expiration_subtitle: 'Define o limite de tempo máximo para uma pesquisa a ser respondida após o envio ',
    select: 'Selecione',
    interval: 'Intervalo',
    description: 'Impede o envio para um mesmo destinatário durante o intervalo de tempo',
    block: 'Bloqueio por pesquisa',
    block_tooltip: 'O bloqueio pode ser ativado por pesquisa ou por padrão para todos os envios.'
  },
  role: {
    new: 'Informe o nome do grupo',
    user_title: 'Usuários',
    groups: 'Grupos',
    name_group: 'Nome',
    search: 'Buscar',
    value: 'Valor',
    add_field: 'Adicionar campo',
    delete_field: 'Excluir campo',
    group_updatedAt: 'Última atualização',
    group_createdAt: 'Data de criação',
    search_group: 'Buscar grupo',
    can_send_title: 'Pesquisas que este grupo pode enviar',
    can_send_description: 'deixe em branco se não quiser filtrar por origem',
    can_edit_title: 'Pesquisas que este grupo pode acessar',
    can_edit_description: 'deixe em branco para não filtrar por pesquisa',
    filter_title: 'Filtro de conteúdo',
    filter_description: 'quando definido, restringe o acesso ao conteúdo que coincida com este filtro',
    create: 'Criar grupo',
    delete: 'Excluir grupo',
    update: 'Atualizar grupo',
    confirm_action: 'Confirmar esta ação',
    delete_item: 'Tem certeza?',
    delete_group_confirm: ' Deseja excluir o grupo?',
    select_users: 'Selecionar usuarios',
    deleted: 'Grupo Excluido',
    empty_group: 'Você ainda nao possui grupos',
    empty_group_msg: 'Crie regras de uso personalizadas para grupos de usuários específicos.',
    error_group: 'Não é possível excluir um grupo com usuários relacionados'
  },
  menu: {
    panel: 'Meu painel',
    surveys: 'Pesquisas',
    user_role: 'Usuários / Permissões',
    reports: 'Relatórios',
    textual_analysis: 'Análise textual',
    approaches: 'Abordagens',
    my_profile: 'Meu perfil',
    my_account: 'Minha conta',
    send_setting: 'Configurações de envio',
    users_group: 'Grupos de usuários',
    metadata: 'Campos personalizados',
    help: 'Ajuda',
    help_sub: 'Suporte da conta',
    help_user: 'usuário',
    exit: 'Sair',
    accounts: 'Listagem de contas',
    change_version: 'Acessar a versão antiga',
    help_chat: 'Suporte Chat',
    help_email: 'Suporte Email',
    tickets: 'Atendimentos',
    auto_messages: 'Mensagens Automáticas'
  },
  searchMenu: {
    advanced_filters: 'Filtros avançados',
    export_data: 'Exportar dados'
  },
  sendingsBar: {
    metadata: 'Metadados'
  },
  export: {
    user: 'Exportar usuários',
    title: 'Exportar',
    generate_pdf: 'Gerar PDF',
    cancel: 'Cancelar',
    subtitle: 'A transferência do arquivo pode demorar alguns instantes, conforme a quantidade de registros.',
    time_zone: 'Selecione o fuso horário',
    delimiter: 'Separador de colunas',
    download: 'Seu arquivo está sendo processado em segundo plano, em breve você terá acesso a ele.',
    download_on_email: 'A exportação está sendo processada. Você receberá em seu e-mail assim que for concluída.',
    custom_delimiter: 'Digite o separador customizado',
    without_fields: 'Exportar sem os campos personalizados',
    select_fields: 'Selecionar um ou mais campos',
    choose_fields: 'Escolher campos personalizados a serem exportados',
    show_ticket_info: 'Exportar com informações de atendimento',
    choose_field_metadata: 'Selecione o campo que corresponde ao CPF.',
    subtitle_bacen: 'Selecione mês e ano para exportar.',
    mounth: 'Mês:',
    year: 'Ano:',
    dates_utc: 'Datas no fuso horário (UTC)',
    dates_local: 'Datas no fuso horário local',
    mounth_selected: {
      jan: 'Jan',
      feb: 'Fev',
      mar: 'Mar',
      apr: 'Abr',
      may: 'Mai',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Set',
      oct: 'Out',
      nov: 'Nov',
      dec: 'Dez'
    },
    separators: {
      comma: 'Vírgula (padrão)',
      semicolon: 'Ponto e vírgula',
      tab: 'Tabulação',
      custom: 'Separador personalizado'
    }
  },
  real_time: {
    graphics: 'Gráficos',
    responses: 'Respostas',
    fullscreen: 'Tela cheia',
    not_answered: 'Esta pergunta não tem respostas para exibir.',
    see_at_responses: 'Clique para ver em avaliações',
    auto_scroll: 'Rolagem automática',
    see_details: 'Clique para ver detalhes'
  },
  sentimentals: {
    title: 'Distribuição de sentimentos de resposta',
    data_not_found: 'Dados não encontrados',
    feelings: 'Sentimentos',
    negative: 'Negativo',
    positive: 'Positivo',
    neutral: 'Neutro',
    negatives: 'negativos',
    positives: 'positivos',
    neutrals: 'neutros',
    total: 'Total',
    answer_text: 'Respostas tipo texto',
    gauge_legend: '% dos comentários são positivos',
    check_date_filters: 'Confira os filtros de data',
    comments: 'comentários',
    gauge_chart_title: 'Score de sentimento',
    doughnut_chart_title: 'Distribuição de sentimentos',
    bar_chart_title: 'Sentimento ao longo do tempo',
    line_chart_title: 'Score de sentimento no tempo',
    total_comments: 'Total de comentários',
    comments_up: 'O número de comentários subiu em relação ao período anterior',
    comments_down: 'O número de comentários baixou em relação ao período anterior'
  },
  exceeded: {
    upgrade: 'É hora de atualizar seu plano.',
    limit: 'Você atingiu o limite de envios gratuitos. Para enviar mais você precisa escolher um plano que melhor atenda a sua demanda.',
    keep: 'Continuar enviando',
    notshow: 'Não mostrar novamente',
    thanks: 'Obrigado!',
    process: 'Estamos processando seu pedido , aguarde que um de nossos atendentes entrará em contato'
  },
  account_config: {
    company_name: 'Nome da empresa',
    from: 'Remetente',
    email_signature: 'Assinatura do email',
    colors_settings: 'Configurar cores',
    blend_mode: 'Remover fundo branco do logo principal',
    text_light: {
      label: 'Cor do texto',
      light: 'Claro',
      dark: 'Escuro'
    },
    reset_password: 'O email para redefinição da senha foi enviado.',
    change_password: 'Redefinir Senha',
    type_user: 'Tipo de usuário',
    validate_email: 'Email inválido ou já cadastrado',
    error_create_account: 'Nome ou e-mail inválido',
    email: 'Email',
    name: 'Nome',
    phone: 'Telefone',
    language: 'Idioma',
    user_saved: 'Permissões de usuário atualizadas',
    main_image: 'Imagem principal da empresa',
    main_logo: 'Logo principal',
    small_logo: 'Logo pequeno',
    created_date: 'Data de criação',
    delete_user: 'Deseja excluír o usuário ?',
    delete_confirm: {
      yes: 'Sim',
      no: 'Não'
    },
    user_deleted: 'Usuário excluído',
    error_delete: 'Erro ao excluír usuário',
    fromSystem: 'Do sistema',
    fromSurvey: 'Da pesquisa',
    primaryColor: 'Cor primária',
    secondaryColor: 'Cor secundária',
    myCompanyInfo: 'Informações da minha empresa',
    configImg: 'Configurar imagens',
    colors: {
      superiorMenu: 'Menu superior, botões',
      icons: 'Ícones, textos de botões, detalhes',
      surveyMenu: 'Menu superior de pesquisa'
    },
    phone_input: {
      country_code: 'Código do país',
      choose_country: 'Escolha um país',
      phone_number: 'Número de telefone',
      example: 'Exemplo :'
    }
  },
  isAnonymous: {
    is_anonymous: 'Tornar pesquisa anônima',
    anonymous_auth: 'Requerer autorização dos dados analíticos',
    anonymous_question: 'Autorizar exibição das respostas para análise de dados',
    anonymous_title: 'Autorização'
  },
  intelligence: {
    title: 'Binds Intelligence',
    new_chat: 'Nova conversa',
    suggestions: 'Sugestões',
    tooltips: {
      chat_view: 'Alterar a visualização do chat',
      start_chat: 'Iniciar um novo chat',
      more_options: 'Mais opções',
      delete_alert: 'Você não poderá desfazer essa ação.'
    },
    delete: 'Apagar',
    response_not_found: 'Resposta não encontrada',
    chat: {
      inputs: {
        survey: 'Selecione a pesquisa para análise:',
        date: 'Selecione o período da análise:',
        metadata: 'Informe algum campo personalizado para considerarmos nas consultas do tipo ranking.',
        question: 'Selecione uma pergunta para considerarmos no ranking',
        all: 'Todas'
      },
      placeholders: {
        ranking_metadata: 'Campo personalizado para o ranking',
        ranking_question: 'Pergunta para o Ranking',
        question: 'Pergunta',
        survey: 'Pesquisa',
        metadata: 'Campo personalizado'
      },
      buttons: {
        copy: 'Copiar',
        good_answer: 'Boa resposta',
        bad_answer: 'Resposta ruim',
        go_to: 'Navegar para a tela de referência'
      },
      advanced_filters: 'Filtros avançados',
      filters: 'Filtros',
      classification_error: 'Não foi possível identificar a pergunta, tente ser mais específico.',
      description: `<p>
          Bem-vindo ao <strong>Binds Intelligence</strong>! 
          <br />
          Aqui, você conta com um assistente preparado para simplificar informações e oferecer insights valiosos. 
          <strong>O que você gostaria de saber hoje?</strong>
      </p>
      `
    }
  },
  dashboard: {
    survey_deleted_alert: 'Esta Pesquisa foi Excluída.',
    survey_deleted: 'Pesquisa Excluída',
    send_type: 'Tipo de envio',
    option: 'Opção',
    views: {
      average: 'Métrica',
      overtime: 'No tempo',
      overall: 'Distribuição'
    },
    no_elegible_component: 'A pesquisa não possui componente elegível para ser exibido nesse relatório.',
    add_filter: 'Adicionar um filtro',
    include_fav: 'Incluir aos favoritos',
    save_and_apply: 'Salvar e aplicar filtro',
    favorites: 'Favoritos',
    use_filter: 'Usar este filtro',
    clear_filters: 'Limpar filtros',
    apply_filters: 'Aplicar filtros',
    apply_query: 'Adicionar filtros',
    type_value: 'Digite o valor do campo',
    type_filter_title: 'Digitar o título do filtro',
    no_referrals: 'A pesquisa selecionada não tem indicações para mostrar.',
    no_responses: 'Não foram encontradas respostas para o período selecionado.',
    overview: 'Visão Geral',
    real_time: 'Dashboard',
    individual_responses: 'Avaliações',
    individual_response: 'Avaliação',
    no_responses_label: 'Sem avaliações',
    has_filter: 'Possui filtro',
    config_graphic: 'Configurar',
    loading_graphic: 'Carregando gráfico...',
    loading_data: 'Carregando ...',
    individual_answers: 'Respostas',
    referrals: 'Indicações',
    referral: 'Indicação',
    ranking: 'Ranking',
    performance: 'Performance',
    referred_by: 'Indicado por',
    word_cloud: 'Nuvem de Palavras',
    therm_not_found: 'Termo não encontrado',
    bubbles: 'Bolhas',
    list: 'Lista',
    period: {
      '24hours': '24 horas',
      '7days': '7 dias',
      '30days': '30 dias',
      '6months': '6 meses',
      '12months': '12 meses'
    },
    legends: 'Legendas',
    success_update: 'Atualizado!',
    error_update: 'Erro ao tentar atualizar',
    update_card: 'Atualizar Gráfico',
    missing_question_and_graphic: 'Escolha a questão e o gráfico!',
    error_clone_card_text: 'Não é possível duplicar card do tipo texto.',
    create_card: 'Criar Gráfico',
    create_new_card: 'Novo Gráfico',
    change_what_display: 'Editar Gráfico',
    choose_title: 'Título',
    add_filter_advanced: 'adicionar filtro personalizado',
    filter_advanced: 'Filtro avançado',
    not_edit_question: 'Não é possível editar a pergunta',
    choose_question: 'Escolha uma pergunta',
    add_new_card: 'Adicionar novo gráfico',
    save_card: 'Salvar card',
    success_delete_card: 'Card deletado com sucesso!',
    error_delete_card: 'Erro ao deletar card.',
    success_clone_card: 'Card duplicado com sucesso!',
    error_clone_card: 'Erro ao duplicar card.',
    you_can: 'Você pode criar um card personalizado a partir deste!',
    no_customization: 'Você ainda tem um dashboard personalizado para essa pesquisa',
    no_customization_subtitle: 'Personalize e volte aqui!',
    success_save_config: 'Salvo!',
    error_save_config: 'Erro ao salvar personalização.',
    no_data_to_show: {
      title: 'Sem respostas para mostrar',
      subtitle: 'As opiniões de seus clientes serão mostradas aqui.',
      unnamed: 'Sem nome'
    },
    graphics: {
      delete_graphic: 'Deseja deletar este gráfico?',
      clone_graphic: 'Deseja duplicar este gráfico?',
      actions: 'Ações',
      showing_responses: 'Por resposta',
      showing_average: 'Por média',
      average: 'Média',
      nvs_op1: 'Significativamente melhor',
      nvs_op2: 'Um pouco melhor',
      nvs_op3: 'Nem melhor, nem pior',
      nvs_op4: 'Um pouco pior',
      nvs_op5: 'Significativamente pior',
      no_overtime_matrix: 'Não há gráfico overtime para o componente matriz.',
      vu: 'Muito insatisfeito',
      un: 'Insatisfeito',
      ind: 'Indiferente',
      sat: 'Satisfeito',
      satisf: 'Satisfatório',
      vs: 'Muito satisfeito',
      vb: 'Muito ruim',
      bd: 'Ruim',
      st: 'Bom',
      vg: 'Muito bom',
      ex: 'Excelente',
      le: 'Pouco esforço',
      me: 'Muito esforço',
      ee: 'Esforço esperado',
      op1: 'Sim',
      op2: 'Não',
      op3: 'Não sei',
      op4: 'Talvez',
      op: 'Opção',
      detractors: 'Detratores',
      promoters: 'Promotores',
      neutral: 'Neutros',
      detractor: 'Detrator',
      promoter: 'Promotor',
      neutralSing: 'Neutro',
      neutralString: 'Neutro',
      generated_palette: 'Paleta de cores gerada:',
      delete_card: 'Deletar gráfico',
      clone_card: 'Duplicar gráfico',
      choose: 'Escolha um gráfico',
      choose_display: 'Escolha o tipo de exibição',
      choose_display_graphics: 'Escolha o tipo de exibição do gráfico:',
      by_responses: 'Por respostas',
      by_metric: 'Por métrica',
      change_question: 'Escolha a pergunta base:',
      options: 'Opção de gráficos:',
      choose_color: 'Escolha a cor principal do seu gráfico:',
      percentage_distribution: 'Distribuição em porcentagem',
      online_distribution: 'Distribuição em linha',
      gauge: 'Medidor',
      percentage_bar: 'Barra com porcentagem',
      single_line: 'Linha',
      lines: 'Linhas',
      bar: 'Barras',
      horizontal_bar: 'Barra horizontal',
      vertical_bar: 'Barra Vertical',
      pie: 'Pizza',
      percentage: 'Porcentagem',
      percentage_pie: 'Pizza com porcentagem',
      bubbles: 'Bolhas',
      stacked: 'Barras empilhadas',
      table: 'Tabela',
      text_color: 'Escolha a cor dos textos:',
      customized_dash: 'Personalizado',
      selected_option: 'Opção selecionada:',
      total: 'total',
      likes_in_time: 'de likes no tempo'
    },
    textUi: {
      choices: 'Quantidade de escolhas:',
      option: 'Opção mais escolhida:'
    },
    gauge_analytics: {
      labels: {
        terrible: 'Péssimo! Recomendamos ações rápidas para mudar essa percepção em seus clientes',
        bad: 'Ruim! Existem muitas oportunidades de melhorias',
        good: 'Bom! Mas possivelmente existem muitas oportunidades de melhorias',
        great: 'Ótimo! Sua empresa já possui uma ótima percepção com os clientes',
        excellent: 'Excelente! Você está em um grau de excelência com seus clientes'
      },
      labels_nvs: {
        bad: 'Ruim, precisa melhorar',
        moderate: 'Moderado, pode melhorar',
        good: 'Bom',
        great: 'Excelente',
        excellent: 'Excepcional'
      },
      metrics: {
        NPS: {
          bad2: 'De -51 a -100',
          bad1: 'De -1 a -50',
          good: 'De 0 a 49',
          great: 'De 50 a 74',
          excellent: 'De 75 a 100'
        },
        CSAT: {
          oneTo5: {
            bad: 'De 0 a 29.9',
            good: 'De 30 a 49.9',
            great: 'De 50 a 79.9',
            excellent: 'De 80 a 100'
          },
          zeroTo10: {
            bad: 'De 0 a 29.9',
            good: 'De 30 a 49.9',
            great: 'De 50 a 79.9',
            excellent: 'De 80 a 100'
          }
        }
      }
    },
    survey_overview: 'Resumo das suas pesquisas',
    recent_surveys: 'Pesquisas recentes',
    see_all: 'Ver todas as pesquisas',
    see_more: 'Ver Mais',
    sendings: 'Envios',
    openings: 'Abertos',
    drafts: 'Rascunhos',
    paused: 'Pausada | Pausadas',
    surveys: 'Pesquisas',
    responses: 'Respostas',
    unsubscribes: 'Desinscritos',
    spam: 'Spam',
    clicks: 'Clicks',
    conclusion_rate: 'Taxa de conclusão',
    average_time: 'Tempo médio',
    title: 'Escolha uma pesquisa no menu acima',
    subtitle: 'e analise as respostas com nossos gráficos e listas!',
    y: 'Sim',
    n: 'Não',
    mb: 'Talvez',
    not_found: 'Gráfico não encontrado, reveja os filtros',
    most_choosen: {
      1: 'Essa foi a alternativa mais escolhida dentre as opções, ',
      2: ' dos usuários optaram por ela.',
      3: 'Esse foi o valor mais escolhido dentre as opções, com um total de ',
      4: ' escolhas.'
    },
    no_response: 'Sem resposta',
    msg_no_response: 'Parece que ainda não houve respostas',
    no_name: 'Nome não informado',
    no_attendant: 'Atendente não encontrado',
    no_email: 'Email não informado',
    no_phone: 'Telefone não informado',
    not_finished: 'Não finalizado',
    mail_not_opened: 'Email não aberto',
    mail_not_send: 'Email não enviado',
    attend: 'Atender',
    send: 'Envio',
    answer: 'Resposta',
    last_answer: 'Última Resposta',
    opening: 'Abertura',
    done: 'Finalizado',
    matrix_component: 'Componente matrix'
  },
  dashboard_card: {
    anwsers: 'Resposta de | Respostas de',
    sendings: '{count} envio | {count} envios',
    average_time: 'Tempo médio: {count} min',
    anwsers_link: 'Respostas',
    edit: 'Editar',
    clone: 'Duplicar',
    tv_dash: 'Dash',
    delete: 'Excluir',
    rate: 'Taxa de resposta'
  },
  performance: {
    select: 'Selecione',
    select_two: 'Selecionar',
    selected: 'Selecionado',
    to_remove: 'Remover',
    mark_off: 'Desmarcar',
    max_elements: 'Máximo de seleções atingido. Remova uma opção selecionada para selecionar outra.',
    no_result: 'Nenhum resultado encontrado. Considere alterar a consulta de pesquisa.',
    no_options: 'A lista está vazia.',
    surveys_selected: 'pesquisas selecionadas',
    choose_period: 'Escolha o período',
    choose_param: 'Escolha o parâmetro',
    ten_surveys: 'Escolha até 10 pesquisas',
    ungroup: 'Ver pesquisas desagrupadas',
    between_surveys: 'Performance entre pesquisas',
    overtime_between_surveys: 'Performance entre pesquisas pelo tempo',
    sendings: 'Envios',
    opening_rate: 'Taxa de Abertura',
    answer_rate: 'Taxa de Resposta',
    complete_response_rate: 'Taxa de Respostas Completas',
    abandonment_rate: 'Taxa de Abandono',
    response_time: 'Tempo Médio de Resposta (em minutos)',
    day: '24 HORAS',
    week: '7 DIAS',
    month: '30 DIAS',
    semester: '6 MESES',
    year: '12 MESES',
    only_ten: 'Você pode selecionar no máximo 10 pesquisas'
  },
  survey_store: {
    input_add_label: 'Adicionar indicação',
    input_name_placeholder: 'Nome',
    input_email_placeholder: 'E-mail',
    input_phone_placeholder: 'Telefone',
    input_url_placeholder: 'URL',
    input_textarea: 'Digite aqui',
    input_name: 'Digite seu nome',
    options: {
      name: 'Nome',
      email: 'Email',
      phone: 'Telefone',
      other: 'Outro'
    }
  },
  start: {
    from_stratch: 'Começar do zero',
    from_template: 'Escolher um template',
    survey_title: 'Título da pesquisa',
    survey_title_placeholder: 'Título da pesquisa',
    title: 'Vamos começar sua primeira pesquisa',
    description: 'Você pode iniciar uma pesquisa do zero e construir cada detalhe ou através de um template validado por nossos especialistas',
    modal_survey_title: 'Digite o nome da pesquisa',
    confirm_email: 'Por favor, confirme seu email para poder enviar a pesquisa',
    confirm_email_first: 'Confirme sua conta através do email que enviamos para você, é possível reenviar a confirmação clicando neste botão abaixo',
    confirm_email_title: 'Confirme sua conta para continuar',
    confirm_email_resend: 'Reenviar!',
    confirm_email_resended: 'Email reenviado!',
    create: 'Criar',
    error_creating: 'Erro ao criar pesquisa.',
    survey_templates: 'Modelos de Pesquisa',
    template_title_error: 'Título da pesquisa obrigátorio.'
  },
  survey: {
    settings: {
      set_question: 'Configurar pergunta',
      set_messages: 'Configurar mensagens',
      set_theme: 'Configurar tema',
      set_responses: 'Configurar respostas',
      question: 'Pergunta',
      conditions: 'Condições',
      messages: 'Mensagens',
      theme: 'Tema',
      responses: 'Respostas',
      colors: 'Cores',
      logos: 'Logos',
      animations: 'Animações',
      locks: 'Bloqueios',
      anonymity: 'Anonimato',
      start_msg: 'Mensagem inicial',
      end_msg: 'Mensagem final',
      answered_msg: 'Mensagem já respondida',
      no_condition_end_message: 'A mensagem padrão não pode ser reposicionada ou condicionada.'
    },
    pt: 'Português',
    en: 'Inglês',
    es: 'Espanhol',
    matrix: 'matriz',
    text: 'texto',
    referral: 'indicação',
    enum: 'personalizado',
    exit_whithout_save: 'Tem certeza que deseja sair?',
    exit_confirm: 'Continuar sem salvar',
    exit_message: 'É possível que as alterações feitas não sejam salvas.',
    save_and_open_ticket: 'Salve a pesquisa antes de iniciar a configuração.',
    save_and_continue: 'Salvar e continuar',
    bold: 'Negrito',
    italic: 'Itálico',
    underlined: 'Sublinhado',
    metadata: 'Metadado',
    title_on_reports: 'Título da pergunta nos relatórios',
    custom_title: 'Título personalizado',
    show_progress_bar: 'Exibir barra de progressão',
    hide_button_previous: 'Ocultar botão de voltar',
    details: 'Detalhes',
    metadata_value: 'Valor do campo',
    display_rules: 'Exibir quando',
    all_rule: 'Todas condições forem verdadeiras',
    any_rule: 'Qualquer condição for verdadeira',
    is_op: 'for',
    is_not_op: 'não for',
    hide_logo: 'Esconder logo',
    hide_title: 'Esconder título',
    conditions_title: 'Condições',
    for_metadata: 'Para campos personalizados',
    for_responses: 'Para respostas',
    add_condition: 'Adicionar condição',
    new_condition: 'Nova condição',
    confirm: 'Confirmar',
    count_of_conditions: 'Total de condições',
    condition_title: 'Está pergunta possui resposta no título',
    condition_title2: 'Apague a indicação de resposta para poder mover a questão',
    condition_position: 'Você tem condições que dependem da posição dessa pergunta!',
    condition_position2: 'Você configurou fluxos condicionais para esta pesquisa que envolvem a posição desta pergunta. Caso escolha mudar a pergunta para esta posição, precisamos que nos permita excluir tais condições. Deseja continuar?',
    conditions_msg: 'Você tem condições que dependem dessa pergunta!',
    conditions_msg2: 'Você configurou fluxos condicionais para esta pesquisa que envolvem essa pergunta. Caso escolha mudar a pergunta, todas as condições que dependem dela serão deletadas.',
    auto_messages_title: 'Ao deletar ou trocar o componente de pergunta, todas as configurações atreladas a ele serão perdidas.',
    auto_messages_msg: 'Deseja continuar?',
    continue: 'Continuar',
    cancel: 'Cancelar',
    lock_time: 'Bloqueio de resposta por tempo',
    send_lock_time: 'Bloqueio de envio por tempo',
    send_block_description: 'Este bloqueio de envio por pesquisa será sempre considerado se estiver ativo, por mais que nas configurações da conta já exista um bloqueio de envio configurado.',
    expire_lock_time: 'Expirar envio por tempo',
    expire_block_description: 'Essa regra configura que o respondente tenha um período para responder a pesquisa.',
    config_blocking: 'Configurar bloqueios',
    unique_key: 'Chave única para bloqueio',
    unique_key_desc: 'E-mail ou telefone, ou algum campo personalizado',
    config_anonymous: 'Configurar anonimato',
    seconds: 'Segundos',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Dias',
    weeks: 'Semanas',
    months: 'Meses',
    email_or_phone: 'Email ou Telefone',
    add_http: 'Adicione http: // ou https: // para a url',
    url_redirect: 'URL para redirecionamento',
    time_for_redirect: 'Tempo para redirecionamento (em segundos)',
    redirect: 'Redirecionar',
    no_animation: 'Sem animação',
    components: {
      csat: 'Monitore a satisfação em pontos estratégicos da jornada',
      nps: 'Monitore a probabilidade de recomendação e fidelidade',
      kpi: 'Monitore a performance de diversos aspectos organizacionais',
      matrix: 'Avalie diversos itens de uma só vez',
      ces: 'Monitore o esforço e possíveis dificuldades do cliente ',
      text: 'Componentes de texto para sua pesquisa',
      referral: 'Solicite indicações aos seus clientes',
      enum: 'Apresente uma lista de opções com componentes múltipla escolha',
      nvs: 'Veja a opinião no cliente de forma comparativa com a concorrência',
      cev: 'Avalie o vínculo emocional do cliente com a sua marca'
    },
    gradient: 'Utilizar botões coloridos (degradê)',
    add_question: 'Adicionar Pergunta',
    bread_edit: 'Edição da Pesquisa',
    bread_setting: 'Configurar Envio',
    bread_send: 'Envio da Pesquisa',
    navbar_move: 'Mover',
    navbar_clone: 'Copiar',
    navbar_delete: 'Excluir',
    select_question: 'Selecionar minha pergunta',
    back_button: 'Voltar',
    draft_saved: 'Rascunho salvo!',
    save_draft: 'Salvar rascunho',
    draft_not_saved: 'Ocorreu um erro ao salvar',
    question_error: 'Selecione o tipo da pergunta',
    save_and_send: 'Salvar e enviar',
    toggle_title: 'Título da tela',
    toggle_description: 'Texto de descrição',
    toggle_action_button: 'Exibir botão de ação',
    toggle_use_start_message: 'Usar mensagem de início',
    toggle_image_on_top: 'Imagem no topo',
    toggle_logo_on_top: 'Logo no topo',
    toggle_required: 'Resposta obrigatória',
    toggle_others: 'Opção de outros',
    toggle_image_in_footer: 'Imagem no rodapé',
    toggle_allow_come_back: 'Permitir voltar após responder',
    toggle_random: 'Modo randômico',
    toggle_save_as_template: 'Salvar como template',
    template_title_input: 'Título do template',
    template_description_input: 'Descrição do template',
    select_template_category: 'Categoria do template',
    limit_of_sending: 'Você excedeu o limite de envios gratuítos',
    start_button_text: 'Texto do botão',
    embedLink: 'Inserir link',
    linkLabel: 'Rótulo do link',
    linkUrl: 'URL',
    language: 'Idioma da pergunta',
    general_config: 'Geral',
    messages: 'Mensagens',
    start_message_config: 'Mensagem inicial',
    end_message_config: 'Mensagem final',
    answered_message_config: 'Pesquisa já respondida',
    current_question_config: 'Pergunta',
    survey_settings: 'Configurações da pesquisa',
    survey_already_sent: 'Não é possivel realizar essa ação. Essa pesquisa já possui respostas',
    field_required: 'Campo obrigatório',
    min_length: 'Mínimo de caracteres:',
    max_length: 'Máximo de caracteres:',
    define_placeholder: 'Instrução:',
    min_options: 'Mínimo de opções:',
    max_options: 'Máximo de opções:',
    limit_answer: 'Limitar o número de escolhas',
    previous_question_label: 'Rótulo do botão da pergunta anterior:',
    multiple_button: 'Botão',
    label_others: 'Rótulo outros',
    referral_options: 'Opções de indicação',
    ne_option: 'Nome + Email',
    np_option: 'Nome + Telefone',
    nep_option: 'Nome + Email + Telefone',
    nepu_option: 'Nome + Email + Telefone + URL',
    change_placeholders: 'Alterar instrução dos campos',
    left_label: 'Rótulo esquerdo',
    right_label: 'Rótulo direito',
    choose_survey: 'Escolha uma pesquisa',
    survey_not_found: 'Pesquisa não encontrada',
    questions_error: 'Erro ao buscar questões da pesquisa',
    colors_settings: 'Configurar cores',
    logos_settings: 'Configurar logos',
    logos_title: 'Logo no rodapé',
    logos_description: 'Essa imagem será utilizada no rodapé de cada pergunta.',
    add_input: 'Adicionar Campo',
    show_input: 'Exibir Campo',
    required_field: 'Campo Obrigatório',
    instruction: 'Instrução',
    mapping_type: 'Selecione o tipo de campo',
    delete_input: 'Remover Campo',
    copy_of: 'Cópia de',
    option_1: 'opção 1',
    option_2: 'opção 2',
    option_3: 'opção 3',
    option_added: 'opção adicionada ',
    option_other_added: 'Opção de outros adicionada',
    others: 'Outros',
    placeholder: 'Placeholder',
    error_saving: 'Erro ao salvar pesquisa.',
    required_question_sing_1: 'O campo da questão',
    required_question_sing_2: 'é obrigatório.',
    required_question_plu_1: 'Os campos das questões',
    required_question_plu_2: 'são obrigatórios.'
  },
  questionsNav: {
    message_title: 'Insira a mensagem aqui',
    start_message: 'Mensagem inicial',
    end_message: 'Mensagem final',
    answered_message: 'Já respondida',
    answered_message_value: 'Esta pesquisa já foi respondida.',
    add_question: 'Adicionar pergunta',
    add_end_message: 'Adicionar mensagem final',
    details: {
      value: 'Adicione detalhes à mensagem aqui.',
      link: {
        label: 'Texto para o link',
        value: 'URL'
      }
    },
    sms_default: ': Conte-nos sobre sua experiência e ajude-nos a melhorar nossos serviços.',
    survey: 'Pesquisa',
    nextLabel: 'Próximo',
    question_title: 'Título da pergunta aqui',
    question_value: 'Pergunta aqui'

  },
  theme: {
    background: 'Fundo',
    header: 'Cabeçalho',
    header_text: 'Texto do cabeçalho',
    survey_background: 'Fundo da pesquisa',
    survey_text: 'Texto da pesquisa',
    buttons: 'Botões',
    buttons_text: 'Texto dos botões',
    footer: 'Rodapé',
    footer_buttons: 'Botões do rodapé',
    hover_buttons: 'Seleção dos botões',
    footer_text: 'Texto do rodapé',
    clear_theme: 'Restaurar padrão',
    save: 'Tema salvo'
  },
  analytics: {
    send_analytics: 'Analisar respostas',
    sendings: 'Envios',
    answers: 'Respostas',
    time: 'Tempo médio',
    anonymous_reply: 'Resposta anônima'
  },
  components: {
    csat_satisfied: 'Satisfeito',
    csat_not_satisfied: 'Insatisfeito',
    nes_big_effort: 'Muito esforço',
    nes_little_effort: 'Pouco esforço',
    nps_very_likely: 'Indicaria',
    nps_unlikely: 'Não indicaria',
    text: 'Comente sua resposta',
    ces: {
      totally_disagree: 'Discordo totalmente',
      disagree: 'Discordo',
      partially_disagree: 'Discordo Parcialmente',
      neutral: 'Neutro',
      partially_agree: 'Concordo Parcialmente',
      agree: 'Concordo',
      totally_agree: 'Concordo totalmente'
    }
  },
  sendhub: {
    generate_qrcode: 'Gerar QR CODE',
    new_qrcode: 'Gerar novo QR CODE',
    copy_code: 'Copiar',
    success_copy: 'Código copiado com sucesso.',
    error_copy: 'Não foi possivél copiar.',
    show_code: 'Ver código',
    hide_code: 'Fechar código',
    yes: 'Sim',
    no: 'Não',
    limit: 'Limite de 20 mil linhas, sugerimos o envio por planilha neste caso.',
    limit_wpp: 'Limite de 5 mil linhas atingido',
    code_to_embed: 'Código para incorporar em seu site',
    column: 'Coluna',
    site_url: 'URL do site',
    widget_closePermanently: 'Fechar Permanentemente',
    widget_position: 'Posição do widget',
    widget_top_left: 'Superior esquerdo',
    widget_middle_left: 'Centro esquerdo',
    widget_bottom_left: 'Inferior esquerdo',
    widget_top_right: 'Superior direito',
    widget_middle_right: 'Centro direito',
    widget_bottom_right: 'Inferior direito',
    widget_bottom: 'Inferior',
    widget_popup: 'Popup',
    widget_button: 'Botão de ação (direita)',
    widget_buttonB: 'Botão de ação (esquerda)',
    show_close_button: 'Exibir botão de fechar',
    widget_size: 'Tamanho (px)',
    height: 'Altura (px)',
    widget_time_to_open: 'Tempo para abrir (segundos)',
    widget_colors: 'Cores',
    background_color: 'Fundo',
    text_buttons_color: 'Texto dos botões',
    buttons_color: 'Botões',
    texts_color: 'Textos',
    email_colors: 'Cores do e-mail',
    type_one_by_one: 'Digite um por um',
    import_contacts: 'Importar contatos',
    file_types: 'Arquivo .csv .xls .xlsx',
    go_to_dashboard: 'Ir para o dashboard',
    emails_with_error: 'Emails com erro',
    phones_with_error: 'Telefones com erro',
    validating_survey: 'Enviando as informações',
    contacts_select: 'Seleção de contatos',
    sent_processing: 'O arquivo está sendo processado',
    sent_success: 'Envio processado!',
    explain_select: 'Você pode adicionar os contatos um por um ou importar usando uma das opções ao lado. Se preferir, copie da sua tabela de contatos e cole aqui. Eles serão adicionados automaticamente à mesma.',
    column_select: 'Selecione a coluna',
    add_line: 'Adicionar Linha',
    add_column: 'Adicionar Coluna',
    fix_contacts: 'Inserir dados corretos',
    some_contacts: 'Alguns contatos não foram enviados.',
    save: 'Salvar',
    clean: 'Limpar tudo',
    is_to_clean: 'Deseja limpar tudo?',
    copy: 'Copiar',
    name: 'Nome',
    phone: 'Telefone',
    email: 'Email',
    from_input: 'Remetente',
    subject_input: 'Assunto',
    email_text: 'Clique aqui para adicionar o texto do e-mail',
    set_image_text: 'Clique aqui para adicionar uma imagem',
    toggle_embedded_question: 'Incorporar primeira pergunta',
    toggle_image_on_top: 'Imagem no topo',
    action_button_text: 'Texto do botão de próximo',
    type_email: 'Enviar por e-mail personalizado',
    type_link: 'Gerar um link de resposta',
    type_sms: 'Enviar por SMS',
    sms_warn: 'Entre em contato com o suporte para habilitar essa opção',
    type_widget: 'Utilizar nosso widget no seu site',
    type_email_and_sms: 'Enviar por e-mail e SMS ao mesmo tempo',
    type_totens: 'Mostrar em terminais físicos',
    toten: 'Abordagem por totem',
    toten_hub_text: 'É muito fácil oferecer a pesquisa para visitantes de sua loja ou evento, mesmo sem ter cadastro. Basta disponibilizar o endereço abaixo em um dispositivo conectado à Internet.',
    link: 'Link para envio',
    link_survey: 'Link da pesquisa',
    duplicate: 'Duplicado',
    invalid: 'Inválido',
    send_phone: 'Telefone',
    status: 'Status',
    sendings_total: 'Total de envios',
    failures_total: 'Total de falhas',
    duplicates_total: 'Total de duplicados',
    view_more: 'Ver mais',
    view_less: 'Ver menos',
    sendings_precessed: 'Envio processado',
    images: 'Imagens',
    upload: {
      riskTitle: 'Já existe um envio sendo processado',
      riskMessage: 'Estamos processando um envio por planilha referente a essa pesquisa, se for feito um novo envio o anterior ainda continuará sendo processado.',
      riskButton: 'Enviar mesmo assim'
    },
    linkHub: {
      allowMoreThanOne: {
        title: 'Permitir mais de um respondente',
        description: 'Permite que mais de uma pessoa responda sua pesquisa. É ideal quando você quer publicar a pesquisa em um site ou em uma rede social sem coletar as informações dos respondentes.'
      },
      showEndSurveyButton: {
        title: 'Exibir opção de encerrar a pesquisa',
        description: 'Exibe o botão de encerrar, para que se possa finalizar a pesquisa em qualquer ponto.'
      },
      endSurveyByInactivity: {
        title: 'Encerrar pesquisa ociosa automaticamente',
        description: 'Encerra automaticamente uma pesquisa ociosa após um intervalo de tempo.'
      },
      colors: {
        button: 'Escolha a cor do botão',
        background: 'Escolha a cor do fundo',
        font: 'Escolha a cor da fonte'
      }
    },
    smsHub: {
      title: 'Editar',
      label: 'Link da Pesquisa',
      label2: 'Responder',
      charactersLimit: 'Limite de caracteres',
      charactersRemaining: 'Número de caracteres restantes',
      smsMetadata: 'Você nao tem permissão para inserir metadados',
      showEndSurveyButton: {
        title: 'Exibir opção de encerrar a pesquisa',
        description: 'Exibe o botão de encerrar, para que se possa finalizar a pesquisa em qualquer ponto.'
      },
      endSurveyByInactivity: {
        title: 'Encerrar pesquisa ociosa automaticamente',
        description: 'Encerra automaticamente uma pesquisa ociosa após um intervalo de tempo.'
      },
      error_messages: 'Erro ao buscar SMS. Tente novamente.'
    },
    emailHub: {
      input_error: 'Por favor, preencha o campo de remetente e assunto do e-mail',
      input_success: 'Configurações de e-mail foram salvas'
    },
    emailSmsHub: {
      step_one: 'Passo 1: Configure o e-mail',
      step_two: 'Passo 2: Configure o SMS'
    },
    totenHub: {
      shut_in: 'Encerrar pesquisa em:',
      seconds: 'segundos'
    },
    empty_table: 'Coloque os contatos na tabela',
    existing_column: 'Coluna já existente',
    widget_action_button: {
      startOpen: 'Exibir ao iniciar',
      closeActionButton: 'Botão de fechar',
      customizedImage: 'Imagem customizada'
    },
    configLayout: 'Configurar layout e tamanho',
    configTitle: 'Configurar título',
    configWidget: 'Configurar Widget',
    font: 'Fonte',
    textProps: {
      bold: 'Negrito',
      underline: 'Sublinhado',
      italic: 'Itálico'
    },
    ofTitle: 'De título',
    ofMessage: 'De mensagem',
    sendInfo: 'Informações de envio',
    borders: 'Bordas',
    hints: {
      widgetPosition: 'Posição em que o widget aparecerá na tela',
      startOpen: 'O conteúdo do widget aparecerá logo que a página for carregada',
      closeActionButton: 'Botão de fechar do botão de ação',
      customizedImage: 'Icone do botão de ação',
      customizedImageLink: 'Link do ícone do botão de ação',
      backgroundColorActionButton: 'Cor de fundo do botão de ação',
      width: 'Largura do widget',
      height: 'Altura do widget',
      widgetTimeToOpen: 'Tempo de abertura do widget',
      borders: 'Curvatura das bordas e botão de fechar',
      showCloseButton: 'Exibição do botão de fechar',
      closePermanently: 'Quando clicado o botão fecha o widget permanentemente',
      font: 'Tipo, tamanho e decoração de fonte',
      backgroundColorTitle: 'Cor de fundo do título',
      textButtonColor: 'Cor do texto dos botões',
      buttonColor: 'Cor de fundo dos botões',
      textsColor: 'Cor do texto do título',
      backgroundColorMessage: 'Cor de fundo da mensagem',
      textColorMessage: 'Cor do texto da mensagem'
    }
  },
  date_send: {
    when_send: 'Quando devemos enviar?',
    send_now: 'Enviar Agora',
    select_date: 'Agendar envio',
    date: 'Data:',
    schedule: 'Horário:',
    send: 'Enviar',
    valid_date: 'Selecione uma Data válida',
    date_time: 'Selecione a data e o horário'
  },
  seeds: {
    copy_data: 'Copiar',
    copy_success: 'Copiado com sucesso.',
    copy_fail: 'Não foi possivél copiar.',
    schedule_title: 'Se desejar, agende o envio',
    schedule_placeholder: 'Agendar envio',
    success: 'Envio processado com sucesso.',
    csv_duplicated_headers: 'Existem colunas com nomes repetidos, por favor corrija e tente realizar o upload novamente.',
    csv_special_character_info: 'Caracteres especiais serão convertidos em underlines caso encontrados no título das colunas',
    csv_null_headers: 'Existem colunas sem nome, por favor corrija e tente realizar o upload novamente.',
    csv_column_error: 'SEM NOME',
    csv_empty_headers: 'Realize o mapeamento das colunas no 3º passo.',
    spreadsheet_import: 'Importação de planilha',
    inform_1: 'Se desejar, é possível seguir para o próximo passo sem a necessidade de utilizar a nossa planilha modelo.',
    inform_2: 'Faça a importação da sua planilha de envio, confirme se os dados estão corretos na exibição e então siga para o próximo passo. (Aceitamos apenas arquivos .csv .xls .xlsx com formatação UTF-8)',
    encode_error: 'Aceitamos apenas arquivos com formatação UTF-8',
    download_model: 'Baixar planilha modelo',
    import_file: 'Importar arquivo',
    map_headers: 'Mapear Colunas',
    step: 'Passo',
    step_1: 'Baixar modelo',
    step_2: 'Importar arquivo',
    step_3: 'Configurar e enviar',
    next_step: 'Próximo passo',
    previous_step: 'Passo anterior',
    send: 'Enviar',
    file: 'Arquivo',
    preview: 'Essa exibição é apenas um preview',
    count_lines: 'Total de linhas no arquivo:',
    map_inform_1: 'Configure quais campos da sua planilha se referem a Nome, Email e/ou Telefone.',
    map_email: 'Coluna de Email',
    map_send_phone: 'Coluna de Telefone',
    map_name: 'Coluna de Nome',
    ignore_column: 'Ignorar coluna',
    select: 'Selecione',
    your_file: 'Seu arquivo',
    your_match: 'Combina com',
    file_size: 'O tamanho do arquivo deve ser menor que',
    current_file_size: 'Tamanho do arquivo atual',
    file_type: 'Formato de arquivo incorreto, aceitamos apenas .CSV / .XLS / .XLSX',
    new_metadata: '+ Criar novo campo personalizado',
    fill: 'Preencha os campos'
  },
  templates: {
    error_getting_templates: 'Erro ao buscar templates.',
    error_saving_template: 'Erro ao salvar template.',
    error_using_template: 'Erro ao usar template.',
    survey_title_template: 'Escolha o título da pesquisa',
    use_template: 'Usar template',
    edit_template_details: 'Editar detalhes do template',
    template_title: 'Título do template',
    template_description: 'Descrição do template',
    popular: 'Definir como popular',
    draft: 'Rascunho'
  },
  emptyState: {
    title: 'Sem respostas para mostrar',
    description: 'As opiniões de seus respondentes serão mostradas aqui.'
  },
  expired: {
    title: 'Oops!',
    token_expired: 'Sua chave de redefinição expirou',
    try_again: 'Tentar novamente',
    pick_date: 'Selecione uma data',
    know_more_binds: 'Conheça binds.co',
    signin: 'Entrar'
  },
  resetPassword: {
    title: 'Nova senha para',
    action: 'salvar',
    newPass: 'Nova senha',
    confirmPass: 'Confirmar nova senha'
  },
  msg: {
    account: 'Conta',
    account_description: 'Descrição da conta'
  },
  global: {
    pick_date: 'Selecione uma data',
    link_copied: 'Link copiado para área de transferência',
    unknown_error: 'Ocorreu um erro desconhecido',
    load_more: 'Carregar Mais'
  },
  http: {
    notFound: 'O recurso que você tentou acessar não está disponível :(',
    you_cant: 'Desculpe, mas você não tem permissão para acessar essa tela.',
    unmapped: 'Ocorreu um erro desconhecido no servidor'
  },
  surveyList: {
    filter_options: {
      no_filter: 'Sem Filtro',
      title_az: 'Título A-Z',
      title_za: 'Título Z-A',
      recent_date_send: 'Data de Envio Mais Recente',
      old_date_send: 'Data de Envio Mais Antiga',
      most_sent: 'Mais Envios',
      least_sent: 'Menos Envios',
      most_answered: 'Mais Respostas',
      least_answered: 'Menos Respostas',
      average_time_ascending: 'Tempo Médio Crescente',
      average_time_descending: 'Tempo Médio Decrescente',
      expired: 'Expiradas',
      not_expired: 'Não expiradas',
      health_ascending: 'Saúde Crescente',
      health_descending: 'Saúde Decrescente'
    },
    health_survey: 'Saúde da pesquisa',
    id_copied: 'ID de pesquisa copiado.',
    expired: 'Esta pesquisa foi expirada. Fale com o suporte caso queira reativá-la.',
    my_surveys: 'Minhas Pesquisas',
    search_by_title: 'Buscar por título',
    active_since: 'Ativa desde',
    last_send: 'Último envio',
    copy_id: 'Copiar ID',
    not_send: 'Pesquisa não enviada',
    created_since: 'Data de criação',
    delete_error: 'Erro ao deletar pesquisa',
    delete_question: 'Tem certeza que deseja deletar essa Pesquisa?',
    delete_success: 'Pesquisa deletada!',
    delete_survey: 'Deletar Pesquisa',
    filter_author: 'Filtrar por autor',
    filter_category: 'Filtrar por categoria',
    filter_type: 'Filtrar por tipo',
    new_survey: 'Nova Pesquisa',
    templates: 'Templates',
    error_go_to: 'Algo deu errado. Tente novamente.',
    you_cant: 'Você não tem permissão para isso.',
    survey_cloned: 'Pesquisa Clonada',
    survey_cloned_error: 'Erro em clonar a pesquisa',
    no_surveys_msg: 'Você ainda não tem pesquisas.',
    create_first_survey: 'Crie a sua primeira!',
    click_add_title: 'Clique aqui para adicionar o título',
    click_add_description: 'Clique aqui para adicionar uma descrição',
    clone_survey: 'Duplicar pesquisa',
    type_title: 'Digite o título',
    go_templates: 'Ir para templates',
    modal_templates_title: 'Precisando de inspiração?',
    modal_templates_msg: 'Preparamos diversos exemplos para você. Escolha um modelo para personalizar e comece a coleta de respostas em instantes.',
    expire: 'expirar',
    activate_capital: 'Ativar',
    activate: 'ativar',
    expire_in: 'Expira em',
    expire_survey: 'Expirar pesquisa',
    activate_survey: 'Remover data de expiração da pesquisa',
    survey_expired: 'Pesquisa expirada',
    are_you_sure: 'Tem certeza que deseja',
    this_survey: 'essa pesquisa?',
    success_expire: 'Data de expiração inserida com sucesso',
    select_date: 'Selecione uma data para expirar a pesquisa',
    error_expire: 'Erro ao expirar pesquisa',
    success_activate: 'Pesquisa ativada com sucesso',
    error_activate: 'Erro ao ativar pesquisa'
  },
  advanced_filters: {
    delete_filter: 'Você tem certeza que deseja deletar?',
    select_type: 'Selecione um tipo',
    select_option: 'Selecione uma opção',
    select_conditional: 'Selecione uma condição',
    title: 'Filtros avançados',
    filter_by_tag: 'Filtro de tags',
    mark_as_solved: 'Marcadas como resolvidas?',
    done_at: 'Respondida até o final?',
    response_type_text: 'Respostas do tipo texto',
    response_text_true: 'Respostas tipo texto que foram preenchidas',
    annotations: 'Contém anotações?',
    contact_name: 'Nome do contato',
    contact_email: 'Email do contato',
    contact_phone: 'Telefone do contato',
    contact_code: 'Código do contato',
    contact_cpf: 'CPF do contato',
    attendances_created: 'Atendimentos criados?',
    attendant_name: 'Nome do atendente',
    ticket_status: 'Status do atendimento',
    responded_at: 'Respondida?',
    clean_empty_questions: 'Não exibir questões não respondidas',
    attendant_user: 'Responsável do atendimento',
    filter_category: 'Categoria do atendimento',
    filter_subcategory: 'Subcategoria do atendimento',
    placeholder_category: 'Selecionar categoria',
    placeholder_subcategory: 'Selecionar subcategoria',
    fields: {
      contains: 'Contém',
      does_not_contain: 'Não contém',
      is_equal: 'É igual',
      is_different: 'É diferente',
      is_define: 'Está definido',
      is_not_define: 'Não está definido',
      is_equal_to: 'É igual a',
      is_diffrent_from: 'É diferente de',
      promoters: 'Promotores',
      neutrals: 'Neutros',
      detractors: 'Detratores',
      satisfied: 'Satisfeitos',
      unsatisfied: 'Insatisfeitos',
      less_effort: 'Pouco esforço',
      expected_effort: 'Esforço moderado',
      much_effort: 'Muito Esforço',
      yes: 'Sim',
      no: 'Não',
      sent: 'Enviados',
      rejected: 'Rejeitado',
      deferred: 'Adiado',
      spam: 'Spam',
      bounced: 'Devoluções',
      soft_bounced: 'Devolvido temporariamente',
      Unsub: 'Inscrição cancelada',
      gte: 'Maior ou igual',
      lte: 'Menor ou igual',
      gt: 'Maior que',
      lt: 'Menor que'
    },
    categories: {
      tags: 'Tags',
      responses: 'Respostas',
      attendance: 'Atendimento',
      contact: 'Contato',
      metadata: 'Campo personalizado',
      questions: 'Questões',
      survey: 'Pesquisa é',
      channel: 'Canais de Envio',
      date_send: 'Data de Envio'
    }
  },
  tags: {
    open_tag_manager: 'Abrir gerenciador de tags',
    add_tag: 'Adicionar tag',
    edit_tag: 'Editar tags',
    exist_tag: 'Está tag já existe',
    confirm: 'Confirmar',
    remove_tag: 'Remover tag',
    create_new_tag: 'Criar nova tag',
    create: 'criar',
    new_tag_name: 'Nome da nova tag',
    new_tag_color: 'Cor da nova tag',
    tag_name: 'Nome da tag',
    tag_color: 'Cor da tag',
    update_tag: 'atualizar',
    see_all: 'ver todas',
    see_more: 'ver mais',
    alert_remove: 'Realmente deseja remover essa tag?',
    subtitle_alert: 'Você está prestes a remover essa tag.',
    see_more_responses: 'Ver todas as respostas'
  },
  approaches: {
    status: 'Status',
    rejected: 'Rejeitada',
    deferred: 'Diferida',
    sendings: 'envios',
    deliveries: 'entregas',
    delivery_rate: 'taxa de entrega',
    openings: 'aberturas',
    clicks: 'cliques',
    answers: 'respostas',
    answer: 'Resposta',
    non_existing_data: 'Dados não existentes',
    mark_solved: 'Marcar como resolvido',
    response_rate: 'taxa de respostas',
    bounces: 'Devolução',
    softBounces: 'Devolução temporária',
    spam: 'Queixa de spam',
    no_results: 'Não há resultados. Verifique os filtros.',
    copy_link: 'Copiar link da Pesquisa',
    archive: 'Arquivar',
    delete: 'Deletar',
    already_archived: 'Já está arquivada.',
    copied: 'Link copiado!',
    profile: 'Perfil do usuário',
    active: 'Ativas',
    archived: 'Arquivadas',
    all: 'Todas',
    user_not_found: 'Usuário não encontrado.',
    unarchive: 'Desarquivar',
    not_answered: 'Não foi respondida',
    not_opened: 'Não foi aberta',
    confirm_delete: 'Tem certeza que deseja apagar este envio e todas suas respostas?'

  },
  pageUsers: {
    name: 'Nome',
    user: 'Usuário',
    users: 'Usuários',
    delete_user: 'Deletar usuário',
    admin: 'Administrador',
    new_user: 'Novo usuário',
    type_user: 'Tipo de usuário',
    user_features: 'Funcionalidades do usuário',
    email: 'E-mail',
    phone: 'Telefone',
    created_at: 'Data de criação',
    activity_at: 'Última atividade',
    search_by_name_email: 'Buscar por nome ou e-mail',
    search_group: 'Buscar grupo',
    message_group: 'O usuário que não estiver em nenhum grupo, não terá restrições de conteúdo.',
    portuguese: 'Português',
    english: 'Inglês',
    spanish: 'Espanhol',
    all_groups: 'Todos',
    marked_groups: 'Marcados',
    unchecked_groups: 'Desmarcados',
    new_account_info: 'Informações de nova conta',
    account_info: 'Informações da conta',
    profile_config: 'Configuração de perfil',
    group_config: 'Configuração de grupos',
    request_password: 'Solicitar nova senha',
    cancel: 'Cancelar',
    tip_password: 'Uma nova senha será definida através do seu e-mail cadastrado',
    editUser: 'Editar usuário',

    tooltip_contact: 'Quando a opção "Esconder contatos" é ativada, a opção "Atendimentos" será automaticamente desativada.',
    tooltip_metadata: 'Quando a opção "Esconder metadados" é ativada, a opção "Edição de metadados" será automaticamente desativada.'
  },
  ranking: {
    moreAnswered: 'Mais respondidos',
    lessAnswered: 'Menos respondidos',
    highlights: 'Destaques',
    search: 'Buscar por metadados',

    hint_nps: 'Avaliação por métrica',
    hint_enum: 'Número de opções selecionadas',

    best: 'Melhores avaliados',
    worse: 'Piores avaliados',
    movimentations: 'Movimentações',
    comparison: 'Em comparação com o último período',
    metadata: 'Metadado',
    quantity: 'Qtd',
    responses: 'Respostas',
    nothing_to_show: 'Não há nada para mostrar. Confira os filtros!',
    custom_field: 'Campo personalizado',
    question: 'Pergunta',
    min_sample: 'Mínimo de respostas',
    first_metadata1: 'Você ainda não possui campos personalizados',
    first_metadata2: '(com a opção "agrupável" ativa)',
    redirect_to_metadata: 'Criar campo agrupável',
    noQuestions: 'A pesquisa não possui perguntas que podem ser exibidas no ranking',
    cannotExport: 'A pesquisa não pode ser exportada, verifique os filtros',

    // export
    positionVariation: 'Variação da posição',
    scoreVariation: 'Variação',
    score: 'Pontuação',
    position: 'Posição',
    average: 'Média',
    beforeResult: 'Resultado anterior',
    // metrics
    abbreviation: {
      detractor: 'D',
      neutral: 'N',
      promoter: 'P',
      yes: 'S',
      no: 'N',
      maybe: 'T',
      insatisfied: 'INSAT',
      satisfied: 'SAT',
      mutch_effort: 'ME',
      expected_effort: 'EE',
      less_effort: 'LE',
      don_t_know: 'NS',
      excellent: 'EX',
      very_good: 'MB',
      bad: 'RU',
      very_bad: 'MR',
      significantly_better: 'SM',
      a_little_better: 'PM',
      neither_better_nor_worse: 'N',
      a_little_worse: 'PP',
      significantly_worse: 'SP'
    }
  },
  notifications: {
    notify_settings: 'Configuração de notificações',
    notify_settings_health: 'Configuração de notificações de Saúde',
    notifications: 'Notificações',
    no_group: 'Sem grupos cadastrados',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    roles: 'Grupos',
    users: 'Usuários',
    smsDisabled: 'Envio por SMS está desabilitado para este usuário'
  },
  redirect: {
    redirect: 'Redirecionar',
    time_Redirect: 'Tempo para redirecionar (em segundos)',
    url_redirection: 'Url para redirecionar',
    erro_redirect: 'Adicione http: // ou https: // para a url'
  },
  TWO_FACTOR: {
    auth: 'Autenticação em dois fatores',
    verification_code: 'Código de verificação',
    we_send_a_code: 'Enviamos um código de verificação para o',
    inside_account: 'cadastrado',
    inform_the_code: ', por gentileza informe o código para continuar.',
    send_new_code_by: 'Enviar novo código por',
    wait_to_send_again: 'Aguarde para enviar novamente',
    dialog: {
      expired_code_message: 'O seu código foi expirado, enviamos um novo, por favor aguarde.',
      fill_in: 'Preencha todos os campos para continuar.',
      success_send: 'Código reenviado com sucesso'
    }
  },
  userFeatures: {
    questionsMetadata: 'Editor avançado',
    seedBatches: 'Envios',
    metadataEditing: 'Edição de metadados',
    sendingsSurveyLink: 'Obter o link da abordagem',
    sendings: 'Abordagens',
    bindsSupport: 'Suporte Binds',
    trackTerms: 'Binds Voice Score',
    surveyLink: 'Obter o link da pesquisa',
    tickets: 'Atendimentos',
    surveyEdit: 'Edição de pesquisas',
    loginWithAzure: 'Login com SSO',
    intelligence: 'Intelligence Chat',
    hiddenSendingContacts: 'Esconder contatos',
    hiddenSendingMetadata: 'Esconder metadados',
    disableExports: 'Desativar Exportação'
  },
  pageTitles: {
    login: 'Login',
    dashboard: 'Crie a sua pesquisa',
    surveys: 'Pesquisas',
    template: 'Modelos de pesquisa',
    send: 'Envio de pesquisa',
    email: 'E-mail - Envio de pesquisa',
    sms: 'SMS - Envio de pesquisa',
    widget: 'Widget - Envio de pesquisa',
    link: 'Link - Envio de pesquisa',
    totem: 'Totem - Envio de pesquisa',
    panel: 'Meu Painel',
    overview: 'Visão Geral da pesquisa',
    individual: 'Respostas da pesquisa',
    referrals: 'Indicações',
    ranking: 'Ranking',
    performance: 'Performance de pesquisas',
    realtime: 'Resultado em Tempo Real',
    approaches: 'Abordagens de pesquisa',
    cloud_words: 'Nuvem de palavras',
    sentimental: 'Sentimento',
    ticket: 'Atendimentos',
    users: 'Usuários',
    roles: 'Grupo de Usuários',
    account_list: 'Lista de contas'
  },
  preview: {
    view: 'Visualização',
    add_fields: 'Adicione os campos personalizados para testar o fluxo condicional',
    field_value: 'Valor do campo',
    field: 'Campo',
    clear_all: 'Limpar tudo',
    name: 'Nome',
    email: 'E-mail',
    phone: 'Telefone',
    added_message: 'Este campo já foi adicionado, mas você pode alterar o valor dele aqui.',
    value_changed: 'O valor foi alterado!',
    add_tooltip: 'Adicionar campo',
    select_field: 'Selecione o campo',
    simulate_survey: 'Simular pesquisa',
    reload_survey: 'Recarregar pesquisa'
  },
  bvs: {
    name: 'Nome',
    deselect_all: 'Desmarcar todos',
    search_on_twitter: 'Buscar no twitter',
    current_brands: 'Marcas atuais',
    no_data: 'Sem dados para exibir, verifique os filtros.',
    binds_voice_score: 'Binds Voice Score',
    date_to: 'Até',
    date_from: 'Selecione o período',
    filter_sentiment: 'Filtrar sentimento',
    filter_sentiment_placeholder: 'Todos',
    search_text: 'Buscar texto',
    search_text_placeholder: 'Pesquisar texto',
    filter_user: 'Filtrar por usuário',
    user_selecteds: 'usuários selecionados',
    all_users_selecteds: 'Usuários',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    search: 'Buscar',
    sentiment_overtime_title: 'Menções ao longo do tempo',
    sentiment_synthetic_title: 'Binds Voice Score',
    sentiment_mentions_title: 'Menções',
    sentiment_word_cloud_title: 'Hashtags',
    sentiment_bvs: 'BVS',
    sentiment_nps: 'NPS',
    sentiment_mentions: 'MENÇÕES',
    word_cloud_quote: 'Citação',
    word_cloud_amount: 'Quantidade',
    word_cloud: 'Nuvem de palavras',
    word_cloud_list: 'Lista',
    modal_title: 'Binds Voice Score',
    modal_step1: 'Pesquisando a sua marca...',
    modal_step2: 'Extraindo do twitter e outros lugares...',
    modal_step3: 'Analisando sentimentos...',
    modal_step4: 'Preparando o primeiro conjunto de menções...',
    modal_step5: 'Ajustando os gráficos...',
    modal_step6: 'Está quase pronto, retorne dentro de alguns minutos...',
    search_not_found: 'Usuário não encontrado ou já existe na lista de sugestão.',
    theme: 'Claro',
    type_overtime: 'Quantidade',
    delete_term: 'Remover',
    updating_data: 'Buscando dados...',
    config_users: 'Configurar usuários',
    social_media: 'Mídias Sociais',
    document_title: 'Minhas Fontes',
    placeholder: 'Digite o nome do usuário',
    not_found: 'Não encontrado',
    user_exist: 'Já está na sua lista',
    create_custom_brand: 'Crie a sua própria marca personalizada',
    create_success: 'Criada com sucesso',
    verified_user: 'Usuário verificado',
    create_date: 'Data de criação',
    public_brand: 'Marca pública',
    custom_brand: 'Marca personalizada',
    has_terms: 'Possui termos',
    looking_for_posts: 'Estamos buscando postagens para essa marca',
    delete: 'Excluir',
    add: 'Adicionar',
    change: 'Alterar',
    edit: 'Editar',
    want_to_add: 'Deseja adicionar essa marca?',
    subtitle_to_add: 'Você está prestes a adicionar essa marca a sua conta.',
    want_to_remove: 'Realmente deseja remover essa marca?',
    subtitle_to_remove: 'Você está prestes a remover essa marca da sua conta.',
    limit_reached: 'Limite de contas atingido',
    change_name_title: 'Deseja alterar o nome da sua marca?',
    change_name_subtitle: 'Você está prestes a alterar o nome da sua marca.',
    sentiment: {
      all: 'Todos',
      positive: 'Comentários positivos',
      neutral: 'Comentários neutros',
      negative: 'Comentários negativos'
    },
    share: {
      comments: 'Comentários',
      retweets: 'Retweets',
      likes: 'Curtidas',
      view_on_page: 'Visualizar na página'
    },
    tab_yours_brands: 'Suas marcas',
    tab_upload: 'Upload',
    tab_history: 'Histórico',
    history: {
      title: 'Histórico de uploads',
      total_registers: 'Registros',
      all: 'Todos',
      total: 'Total',
      done: 'Concluído',
      processing: 'Processando',
      pending: 'Pendente',
      error: 'Erro',
      title_list_user: 'Usuário',
      title_list_brand: 'Marca',
      title_list_date: 'Data',
      title_list_status: 'Status',
      title_list_rows: 'Quantidade de linhas',
      title_list_rows_created: 'Última linha criada'
    },
    upload: {
      title: 'Ao importar a sua planilha para envio, confirme se os dados estão corretos.',
      title_date: 'O formato da data deve estar em:',
      title_format: {
        _1: 'Aceitamos apenas arquivos',
        _2: '.csv .xls .xlsx',
        _3: 'com formatação',
        _4: 'UTF-8'
      },
      import_file: 'Importar arquivo',
      max_size_file: 'Tamanho máximo do arquivo:',
      fields_required: 'Campos obrigatórios:',
      text: 'Texto',
      save: 'Salvar',
      back: 'Voltar',
      next: 'Próximo'
    }
  },
  whatsapp: {
    hello: 'Olá',
    text_default: ', conte-nos sobre sua experiência e ajude-nos a melhorar nossos serviços',
    text_footer: 'sua opinião é muito importante nós',
    text_footer_2: 'Não tem interesse? Toque em Parar notificações',
    text_button: 'Responder',
    explain_dynamic_text: 'Texto dinâmico obrigatório, sujeito a alterações pontuais de acordo com nossos templates da META.',
    today: 'Hoje',
    title_template: 'Selecione o template',
    config_template: 'Configurar template',
    config_name: 'Configurar nome de exibição',
    config_img: 'Configurar imagem de exibição',
    name_account: 'Nome da conta',
    name_user: 'Nome do usuário',
    sender_and_receiver: 'Remetente e destinatário',
    tooltip_help_name: 'Nome usado na exibição da mensagem, limite de caracteres: 120.',
    tooltip_help_image: 'Imagem usada na exibição do corpo da mensagem',
    limit_exceeded: 'Limite de envio maior que o permitido',
    alert_title_empty: 'Nome de exibição vazio. A mensagem não foi atualizada.'
  },
  resend: {
    tooltip_info: 'O Reenvio considera apenas pesquisas ativas e abordagens do tipo E-mail, SMS e Whatsapp.',
    label_resend: 'Fazer reenvio',
    resend: 'Reenviar',
    title_schedule: 'Agendamentos',
    label_schedule: 'agendamentos',
    search_by_title: 'Pesquisar por título',
    select_resend: 'Selecionar Envios',
    selected_surveys: 'Envios selecionados',
    config_resend: 'Configurar',
    advance: 'Avançar',
    cancel_resend: 'Cancelar envio',
    total: 'Total',
    type: {
      email: 'E-mail',
      sms: 'SMS',
      whatsapp: 'Whatsapp'
    },
    survey: 'Pesquisa',
    type_resend: 'Tipos de envio',
    details_resend: 'Resumo do Envio',
    total_approaches: 'Total de abordagens',
    close: 'Fechar',
    cancel: 'Cancelar',
    continue: 'Continuar',
    back: 'Voltar',
    order_by: 'Ordenar por',
    select_field: 'Selecionar o campo',
    create: 'Criar',
    field: 'Campo',
    label: 'Rótulo',
    value: 'Valor',
    remove: 'Remover',
    confirm: 'Confirmar',
    field_already_exists: 'Este campo já existe',
    created_success: 'Criado com sucesso',
    select_date: 'Selecionar data',
    write_here: 'Digite aqui',
    title_resend: 'Deseja adicionar um título para este envio?',
    title_cancel_resend: 'Realmente deseja cancelar este envio?',
    label_cancel_resend: 'Você está prestes a cancelar esse envio.',
    no_data_list: 'Não existem agendamentos para exibir no momento.',
    title_date: 'Deseja agendar o envio?',
    title_metadata: 'Adicionar campos personalizados',
    getting_informations: 'Obtendo informações',
    created_at: 'Criado em',
    scheduled_to: 'Agendado para',
    count: 'Quantidade',
    send_for: 'envios via',
    success_delete: 'Envio cancelado com sucesso!',
    error_delete: 'Ops! tente novamente.',
    success_resend_title: 'Reenvio realizado/agendado com sucesso.',
    filter_options: {
      no_filter: 'Sem filtro',
      title_az: 'Título A-Z',
      title_za: 'Título Z-A',
      created_gt: 'Data de criação - ⬆️',
      created_lt: 'Data de criação - ⬇️',
      schedule_gt: 'Data de agendamento - ⬆️',
      schedule_lt: 'Data de agendamento - ⬇️'
    }
  },
  automatic_message: {
    title_config: 'Configurações',
    subtitle: 'Defina suas mensagens automáticas',
    title_delete_message: 'Deseja excluir esta mensagem?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    add: 'Adicionar',
    create: 'Criar',
    save: 'Salvar',
    title_placeholder: 'Digite aqui o título da mensagem',
    delete_tooltip: 'Excluir mensagem'
  },
  insights: {
    no_insights: 'Parece que voce não tem pesquisas que podem gerar insights.'
  },
  health_score: {
    title_graph_average: 'Saúde Média',
    title_graph_approach: 'Saúde da abordagem',
    title_evolution_health: 'Evolução da saúde',
    title_approaches_health: 'Saúde do abordado',
    see_details_in_approaches: 'Ver detalhes da abordagem',
    conversion: 'Conversão',
    filter_churn: 'Probabilidade de churn',
    filter_score: 'Saúde',
    lh_to_lh: 'Baixa para baixa',
    lh_to_mh: 'Baixa para média',
    lh_to_hh: 'Baixa para alta',
    mh_to_lh: 'Média para baixa',
    mh_to_mh: 'Média para média',
    mh_to_hh: 'Média para alta',
    hh_to_lh: 'Alta para baixa',
    hh_to_mh: 'Alta para média',
    hh_to_hh: 'Alta para alta',
    health: {
      health_very_high: 'Saúde muito alta',
      health_high: 'Saúde alta',
      health_mid: 'Saúde média',
      health_low: 'Saúde baixa',
      health_very_low: 'Saúde muito baixa'
    },
    churn: {
      churn_very_high: 'Muito alta',
      churn_high: 'Alta',
      churn_mid: 'Média',
      churn_low: 'Baixa',
      churn_very_low: 'Muito baixa'
    },
    abbreviated: {
      very_high: 'M. ALTA',
      high: 'ALTA',
      mid: 'MÉDIA',
      low: 'BAIXA',
      very_low: 'M. BAIXA'
    },
    tooltip_churn: 'Esse gráfico representa a média do probabilidade de churn das abordagens dessa pesquisa.',
    tooltip_score: 'Esse gráfico representa a média de saúde das abordagens dessa pesquisa.',
    title_card_score: 'Saúde',
    title_card_churn: 'Probabilidade de Churn',
    tooltip_hide_health: 'Esconder saúde da pesquisa',
    tooltip_show_health: 'Exibir saúde da pesquisa',
    tooltip_addressed: 'Este valor reflete a média de todas as abordagens respondidas por este contato, em uma escala de 0 a 100. Ele oferece uma visão geral da saúde das interações ao longo do tempo.',
    tooltip_approach: 'Este valor representa a saúde desta abordagem específica em uma escala de 0 a 100. Ela é calculada com base na média das respostas fornecidas durante esta interação. ',
    tooltip_probability_churn: 'A probabilidade de churn é uma estimativa calculada pela Binds, que considera todas as respostas dadas por este contato para prever a chance de cancelamento ou desistência.'
  }
}
