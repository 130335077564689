<template>
  <div class="chat-sidebar">
    <chat-list />
    <chat v-if="intelligence.currentChat?._id" :key="intelligence?.currentChat?._id" />
    <new-chat v-if="intelligence.showNewChat" />
  </div>
</template>

<script>
import ChatList from './chat-list.vue'
import Chat from './chat.vue'
import NewChat from './new-chat.vue'
import intelligenceMixin from '@/_mixins/intelligence'

export default {
  name: 'IntelligenceChatIndex',
  mixins: [intelligenceMixin],
  components: {
    ChatList,
    Chat,
    NewChat
  },
  data () {
    return {
      sideKey: 0
    }
  }
}
</script>

<style lang="scss">
.chat-sidebar {
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-family: Arial, sans-serif;
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.typing-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #888;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  span:nth-child(1) {
    animation-delay: -0.32s;
  }

  span:nth-child(2) {
    animation-delay: -0.16s;
  }

  span:nth-child(3) {
    animation-delay: 0;
  }
}

@keyframes bounce {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
</style>
