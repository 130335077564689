<template>
  <div>
    <div class="container-sidebar-chat">

      <!-- global loading -->
      <div
        class="content-loading-sidebar-sending"
        v-show="loadingCount > 0"
        style="background: #ffffff;"
      >
        <Circle2 class="spinner-social-sidebar-sending" />
      </div>

      <!-- close sidebar -->
      <div class="header-sidebar-sending">
        <i
          class="material-icons icon-close-sidebar-intelligence"
          @click="toggleSidebar(false)">
          cancel
        </i>
      </div>

      <!-- chat structure -->
      <intelligenceChatIndex v-if="intelligence.isSideBarOpen" />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import intelligenceChatIndex from '@/components/chat/index.vue'
import intelligenceMixin from '@/_mixins/intelligence'

export default {
  name: 'IntelligenceChat',
  props: ['id', 'showSidebarSending'],
  mixins: [intelligenceMixin],
  components: {
    intelligenceChatIndex,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loadingCount: 0
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    user () { return this.$store.getters['account/getUser'] }
  },
  mounted () {
    this.$root.$on('set-loading-intelligence-chat', this.setLoading)
  },
  beforeDestroy () {
    this.$root.$off('set-loading-intelligence-chat')
  },
  methods: {
    showLoading () {
      this.loadingCount++
    },
    hideLoading () {
      if (this.loadingCount > 0) this.loadingCount--
    },
    setLoading (isLoading) {
      if (isLoading) {
        this.showLoading()
      } else {
        this.hideLoading()
      }

      const opacity = this.loadingCount ? 1 : 0
      const loadingContainer = document.querySelector(
        '.content-loading-sidebar-sending'
      )

      gsap.to(loadingContainer, {
        opacity,
        duration: 0.2
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.container-sidebar-chat {
  width: 0vw;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  border-radius: 10px 0 0 15px;
  box-shadow: 0 0 15px 10px #00000030;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .content-loading-sidebar-sending {
    z-index: 61;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-social-sidebar-sending {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }

  .header-sidebar-sending {
    position: absolute;
    width: 100%;
    height: auto;
    padding: 0.5vw;

    .icon-close-sidebar-intelligence {
      position: absolute;
      z-index: 67;
      top: 0.3vw;
      right: 1.2vw;
      color: #5e5e5e;
      font-size: 1.4vw;
      cursor: pointer;
    }
  }

  .content-sidebar-sending {
    width: 100%;
    height: 70%;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
  }
}
</style>
