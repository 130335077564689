<template>
  <section>
    <Menu :showSubMenu="false"></Menu>
    <Breadcrumb></Breadcrumb>
    <div class="data-loading-surveys" v-if="isLoading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div v-if="survey && survey._id !== 1" class="row" :style="{'background-color':survey.colors.bodyColor}">
      <NavigationQuestions class="col-lg-2 col-md-2 col-sm-2"/>
      <div class="mor-container">
        <div class="question-container">
          <div class="question">
            <SurveyCreate v-if="startMessage && endMessage && answeredMessage"/>
            <StartMessages v-if="!startMessage "/>
            <EndMessages v-if="!endMessage "/>
            <AnsweredMessage v-if="!answeredMessage "/>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3" style="padding: 0px !important;">
        <SurveySettings :key="settingsKey"/>
      </div>
      <app-modal
        class="modal-checkout-survey modal-default-primary"
        v-model="modalToConfirmRedirect"
        @secondaryButtonClick="redirectTo($event, false)"
        :title="$t('survey.exit_whithout_save')"
        lightbox
        close
        :secondary-button="$t('survey.exit_confirm')"
        :primary-button="$t('survey.save_and_continue')"
        @primaryButtonClick="redirectTo($event, true)"
        v-on:keyup.esc="modalToConfirmRedirect"
        >
        <p style="text-align: center;">{{ $t('survey.exit_message') }}</p>
      </app-modal>
    </div>
  </section>
</template>

<script>
import Menu from '../components/Menu.vue'
import Breadcrumb from '../components/survey/BreadcrumbSurveys.vue'
import SurveyCreate from '../components/survey/SurveyCreate.vue'
import SurveySettings from '../components/SideBarConfig/surveySettings/Settings.vue'
import NavigationQuestions from '../components/survey/NavigationQuestions.vue'
import StartMessages from '../components/survey/StartEndMessages/StartMessages.vue'
import EndMessages from '../components/survey/StartEndMessages/EndMessages.vue'
import AnsweredMessage from '../components/survey/StartEndMessages/AnsweredMessage.vue'
import { surveyService } from '@/_services'
import { iconFormatter } from '@/_helpers'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'Surveys',
  components: {
    Menu,
    Breadcrumb,
    SurveyCreate,
    NavigationQuestions,
    SurveySettings,
    StartMessages,
    EndMessages,
    AnsweredMessage,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'Send' && !this.userWantToRedirect) {
      this.modalToConfirmRedirect = true
      this.whereToGo = to
      next(false)
      return false
    }
    next()
  },
  beforeDestroy () {
    this.$root.$off('update-survey-settings-key')
  },
  data () {
    return {
      settingsKey: 0,
      question: [],
      isLoading: false,
      userWantToRedirect: false,
      modalToConfirmRedirect: false,
      whereToGo: ''
    }
  },
  computed: {
    survey () { return this.$store.getters['survey/getSurvey'] },
    user () { return this.$store.getters['account/getUser'] },
    startMessage () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.hiddenStartMessage
    },
    endMessage () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.hiddenEndMessage
    },
    answeredMessage () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.hiddenAnsweredMessage
    }
  },
  async mounted () {
    this.isLoading = true
    this.$store.commit('survey/resetToInitialState') // tira qualquer sugeira das pesquisas anteriores no cache
    const survey = await surveyService.getSurvey(this.$route.params.id)

    // set color theme on survey
    const getTheme = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
    survey.colors = getTheme

    // set icons on navigation menu
    const shared = iconFormatter.getSharedIcons()
    survey.questions.forEach(element => {
      const questionModel = shared[window._.findIndex(shared, { type: element.type })]
      if (questionModel) {
        element.icon = questionModel.icon
      }
      // Precisamos fazer isso para pesquisas antigas que não têm a propriedade de estrelas nas questions
      if (!element.stars) {
        element.stars = { active: false, numbers: false }
      }
    })

    // FIRST QUESTION SELECTED VALUE DEFAULT
    survey.questionSelected = 0
    survey.selectedEndMessageIndex = undefined
    this.$store.commit('survey/survey', survey)
    this.isLoading = false
    this.$root.$on('update-survey-settings-key', () => {
      this.settingsKey++
    })
  },
  methods: {
    redirectTo (evt, save) {
      this.userWantToRedirect = true
      if (save) {
        this.$root.$emit('saveSurveyAndExit', this.whereToGo.path)
      } else {
        this.$router.push(this.whereToGo.path)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.mor-container {
  margin: 0 auto;
  padding: 1rem;
  flex: 0 1 auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center !important;
  -webkit-box-pack: center !important;
  -webkit-box-flex: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}
.modal-checkout-survey .modal__wrapper__actions__button {
  font-size: 15px !important;
  margin-inline-start: 5% !important;
  cursor: pointer !important;
}

.modal-checkout-survey .modal__wrapper__close-icon .close {
  color: var(--accent-color) !important;
}

.question-container {
  width: 620px !important;
  border-radius: 10px;
  box-shadow: 0 15px 20px 0 rgba(48, 48, 48, 0.16);
  background-color: #ffffff;
  box-sizing: border-box;
}
.rel {
  position: relative;
}
.data-loading-surveys {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.86);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11000;
  .data-loading-spinner {
    margin-top: 22% !important;
    margin-left: 47% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
</style>
