<template>
  <div class="chat-container">
    <!-- <loadingSpinner v-if='showLoading'> </loadingSpinner> -->
    <!-- Submenu -->
    <div class="submenu" :style="submenu.style">
      <Transition>
        <!-- Filters  -->
        <div class="chat-filters" v-if="submenu.visible">
          <app-select-binds :placeholder='$t("intelligence.chat.placeholders.survey")' :options="surveys" :titleItem="'title'" id="surveys"
            :showIndex="true" :value="currentSurvey" @model="currentSurvey = $event"
            @input="changeSurveyAndSetQuestions" class="filter-select" />

          <app-select-binds :placeholder='$t("intelligence.chat.placeholders.ranking_question")' :options="questions" :titleItem="'question'"
            id="questions" :showIndex="true" :value="currentQuestion" @model="currentQuestion = $event"
            class="filter-select" />

          <app-select-binds :placeholder='$t("intelligence.chat.placeholders.ranking_metadata")' :options="account.fields" :titleItem="'label'"
            id="metadata" :showIndex="true" :value="currentMetadata" @model="currentMetadata = $event"
            class="filter-select" />

          <div class="chat-actions" @click='filterIsOpen = true'>
            <span>{{  $t("intelligence.chat.advanced_filters") }}</span>
            <span v-show="chatInfo?.query?.$and?.length" class="count-filters"> {{ chatInfo?.query?.$and?.length }}</span>
          </div>

          <div class="date-filter-container">
            <v-date-picker class="container-date-picker" v-model="date" color="gray" locale="pt-br" mode="date"
              @input="changeDate" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="content-date-picker">
                  <div class="section-date-picker" v-on="inputEvents.start">
                    <div class="start-date">
                      <span>{{ inputValue.start }}</span>
                    </div>
                    <p>-</p>
                    <div class="end-date">
                      <span>{{ inputValue.end }}</span>
                    </div>
                    <i class="material-icons icon-calendar">calendar_month</i>
                  </div>
                </div>
              </template>

              <template #footer>
                <div class="date-range-buttons">
                  <button v-for="(mockDate, index) in rangeOfDates" :key="index" @click="changeDate(mockDate)">
                    {{ $t(`date_filter.periods.${mockDate}`) }}
                  </button>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </Transition>
    </div>

    <!-- Header Options -->
    <section class="current-chat-header">
      <!-- BUTTON HIDE/SHOW LIST -->
      <i class="material-icons-outlined icon-show-list"
        v-if="!intelligence.showChatList"
        v-title="$t('intelligence.tooltips.chat_view')"
        title-placement="top"
        @click="toggleChatList()"
      >vertical_split</i>

      <p class="title-current-survey">{{ currentSurvey?.title }}</p>

      <i class="material-icons-outlined icon-filter-current-chat"
        v-title="$t('intelligence.chat.filters')"
        title-placement="top"
        ref="toggleLP"
        @click="toggleSubMenu($event)"
      >filter_alt</i>
    </section>

    <!-- Chat Messages -->
    <section ref="chatMessages" class="chat-messages">
    <div
      v-for="(message, index) in messages"
      :key="index"
      @mouseenter="handleHoverOnMessageOptions(index, true)"
      @mouseleave="handleHoverOnMessageOptions(index, false)"
      :class="['message', message.type === 'sent' ? 'message-sent' : 'message-received']"
    >
      <!-- Avatar -->
      <div v-if="message.type === 'received'">
        <img class="avatar" src="./images/avtar/open-graph.png" alt="avatar" />
      </div>

      <!-- Message Content -->
      <div class="message-content">

        <!-- Waiting -->
        <transition name="fade">
          <span v-if="message.thinking" class="typing-indicator">
            <span></span><span></span><span></span>
          </span>

          <!-- Message Done -->
          <span v-else v-html="message.htmlText"></span>
        </transition>

        <!-- Hover Buttons -->
        <transition name="fade">
          <div
            v-if="message.showOptions && !message.thinking"
            class="message-options"
          >
            <button
              class="icon"
              v-title='$t("intelligence.chat.buttons.copy")'
              title-placement="top"
              @click="handleOptionClick('copy', message)"
            >
              <i class="material-icons-outlined">content_copy</i>
            </button>
            <button
              class="icon"
              v-title='$t("intelligence.chat.buttons.good_answer")'
              title-placement="top"
              @click="handleOptionClick('thumbs_up', message)"
            >
              <i class="material-icons-outlined" :class="{ 'icon-active': message.helpful }">thumb_up</i>
            </button>
            <button
              class="icon"
              v-title='$t("intelligence.chat.buttons.bad_answer")'
              title-placement="top"
              @click="handleOptionClick('thumbs_down', message)"
            >
              <i class="material-icons-outlined" :class="{ 'icon-active': message.helpful === false  }">thumb_down</i>
            </button>
            <button
            v-if='message.intent'
              class="icon"
              v-title='$t("intelligence.chat.buttons.go_to")'
              title-placement="top"
              @click="handleOptionClick('forward', message)"
            >
              <i class="material-icons-outlined">arrow_forward</i>
            </button>
          </div>
        </transition>
      </div>
    </div>
  </section>
    <footer class="message-input">
      <div ref="chatInput" contenteditable="true" class="chat-input" @keydown="handleKeyDown" @input="updateText"
        placeholder="Write your message..."></div>
      <i class="material-icons send-icon" @click="fireSendMessage">send</i>
    </footer>

    <FilterModal v-if="filterIsOpen" :hideFavorites='true' @resolved-query='updateQuery'
      :queryCard='chatInfo?.query?.$and || chatInfo.query' @close="filterIsOpen = false" entity="intelligence" />
  </div>
</template>

<script>
import { chatService, surveyService } from '@/_services'
import { dateFormatter, stringToObject } from '@/_helpers'
import FilterModal from '@/components/filters/FilterModal.vue'
import { mapGetters } from 'vuex'
import intelligenceMixin from '@/_mixins/intelligence'

export default {
  name: 'chat',
  mixins: [intelligenceMixin],
  components: {
    FilterModal,
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      text: '',
      surveys: [],
      questions: [],
      optionAll: { question: 'Todas', _id: '' },
      botColor: this.randomColor(),
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year'],
      currentQuestion: null,
      currentSurvey: null,
      currentMetadata: null,
      filterIsOpen: false,
      waitingForAnswer: false,
      date: {
        end: this.$moment().subtract(7, 'days').format(),
        start: this.$moment().format()
      },
      // showLoading: false,
      submenu: {
        visible: false,
        style: {}
      },
      chatInfo: {
        startDate: null,
        endDate: null,
        survey: null,
        question: null,
        questionId: null,
        metadata: null,
        query: {
          $and: []
        }
      }
    }
  },
  async mounted () {
    this.chatInfo = this.intelligence.currentChat
    if (this.chatInfo?.query) {
      this.chatInfo.query = stringToObject(this.chatInfo.query)
    }
    this.$root.$emit('set-loading-intelligence-chat', true)

    this.changeDate({ start: this.intelligence.currentChat.startDate, end: this.intelligence.currentChat.endDate })
    await Promise.all([this.changeSurveyAndSetQuestions({ _id: this.chatInfo?.survey }, this.chatInfo?.questionId), this.getMessages(), this.getSurveys()])
    this.$root.$emit('set-loading-intelligence-chat', false)

    this.currentMetadata = this.account.fields.find(e => e.key === this.chatInfo.metadata)

    // Scroll to bottom after messages are loaded
    this.$nextTick(() => {
      this.scrollToBottom()
    })

    // Add event listener to detect clicks outside
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy () {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount'
    })
  },
  methods: {
    async getSurvey (id) {
      return surveyService.getSurvey(id)
    },
    handleHoverOnMessageOptions (index) {
      this.messages[index].showOptions = !this.messages[index].showOptions
    },
    async handleOptionClick (option, message) {
      if (option === 'copy') {
        await navigator.clipboard.writeText(message.pureText)
        return this.$store.commit('alerts/alert', {
          message: 'Mensagem copiada.',
          position: 'bottomRight',
          showAlert: true
        })
      }

      if (option === 'forward') {
        const query = stringToObject(message.query)
        let name; let fieldDate = 'respondedAt'
        if (message.survey) {
          query?.$and.push({
            survey: {
              $eq: message.survey
            }
          })
        }

        const dateFilter = {
          $gte: message.startDate,
          $lte: message.endDate
        }

        // to do: refactor this and make insights, tickets and ranking work

        if (!query || !message?.intent) return
        switch (message.intent) {
          case 'ranking':
            name = 'Ranking'
            break
          case 'graphs':
            name = 'Overview'
            break
          case 'individual':
            name = 'Individual'
            break
          case 'approaches':
            name = 'Approaches'
            fieldDate = 'createdAt'
            break
          case 'sentiment':
            name = 'Sentimentals'
            break
          case 'insights':
            name = 'Insights'
            break
          case 'tickets':
            name = 'Tickets'
            break
          default:
            break
        }
        query.$and.push({
          [fieldDate]: dateFilter
        })
        return this.$router.replace({ path: '/empty' }).then(() => {
          this.$router.push({ name, query: { q: JSON.stringify(query) } })
        })
      }

      const feedback = { helpful: option === 'thumbs_up' }
      chatService.sendFeedback(message._id, feedback)
      message.helpful = feedback.helpful
    },
    // Close menu when clicking outside
    handleClickOutside (event) {
      const isClickInside = event.target.closest('.submenu, .material-icons-outlined, .container-filter-modal, .option-selector')
      if (!isClickInside) {
        this.submenu.visible = false
      }
    },
    // Toggle the visibility of the submenu
    toggleSubMenu (event) {
      event.stopPropagation()
      const rect = this.$refs.toggleLP.getBoundingClientRect()
      this.submenu = {
        visible: true,
        style: {
          position: 'fixed',
          top: `${rect.top + 34}px`,
          left: `${rect.left - 150}px`,
          boxShadow: '0 0 6px 0px #00000029',
          zIndex: 20
        }
      }
    },
    handleKeyDown (event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.fireSendMessage()
      }
    },
    updateQuery (query) {
      this.chatInfo.query = query
    },
    async changeSurveyAndSetQuestions (survey, questionId) {
      this.questions = []

      if (!survey._id) { return this.toggleSidebar(false) }

      this.currentSurvey = await this.getSurvey(survey._id)

      // Add all questions from the fetched survey
      this.questions = [this.optionAll].concat(this.currentSurvey.questions)

      // Set the current question based on questionId or fallback to the first question
      this.currentQuestion = this.questions.find(q => q._id === questionId) || this.optionAll
    },
    async getMessages () {
      const newChat = await chatService.getMessagesList(this.intelligence.currentChat._id)
      newChat.reverse().forEach(message => {
        this.messages.push({
          htmlText: this.convertToHtml(message.question || 'Pergunta não encontrada'),
          timestamp: message.createdAt,
          sender: 'You',
          type: 'sent'
        })
        const waitAnswer = !message.answer && !this.$moment(message.createdAt, 'YYYY-MM-DD HH:mm ZZ').isBefore(this.$moment().subtract(3, 'minutes'))
        this.messages.push({
          _id: message._id,
          htmlText: this.convertToHtml(message.answer || 'Resposta não encontrada'),
          timestamp: message.createdAt,
          pureText: message.answer,
          helpful: message.helpful,
          thinking: waitAnswer,
          query: message.query,
          survey: message.survey,
          intent: message.intent,
          startDate: message.startDate,
          endDate: message.endDate,
          questionId: message.questionId,
          metadata: message.metadata,
          showOptions: false,
          sender: 'AI Bot',
          type: 'received'
        })
      })
    },
    updateText () {
      this.text = this.$refs.chatInput.innerText.trim()
    },
    changeDate (dates) {
      if (!dates) return
      let start
      let end
      if (typeof dates === 'string') {
        start = dateFormatter.stringPeriodToPlainDate(dates).formatedStart
        end = dateFormatter.stringPeriodToPlainDate(dates).formatedEnd
      } else {
        start = this.$moment(dates.start, 'YYYY-MM-DD HH:mm ZZ').startOf('day').utc().format()
        end = this.$moment(dates.end, 'YYYY-MM-DD HH:mm ZZ').endOf('day').utc().format()
      }
      this.date = { start, end }
    },
    async fireSendMessage () {
      if (this.waitingForAnswer) return
      if (this.text) {
        const messageToSend = {
          _id: this.intelligence.currentChat._id,
          startDate: this.chatInfo?.date?.startDate || this.date.start,
          endDate: this.chatInfo?.date?.endDate || this.date.end,
          survey: this.currentSurvey._id,
          query: this.chatInfo.query,
          questionId: this.currentQuestion._id,
          metadata: this.currentMetadata?.key,
          question: this.text
        }
        this.messages.push({
          htmlText: this.convertToHtml(this.text),
          timestamp: new Date(),
          sender: 'You',
          type: 'sent'
        })

        this.$refs.chatInput.innerText = ''
        const thinkingMessage = {
          thinking: true,
          sender: 'AI Bot',
          type: 'received'
        }
        this.messages.push(thinkingMessage)
        this.waitingForAnswer = true
        const { question, answer } = await this.sendMessage(messageToSend)
        this.waitingForAnswer = false
        this.messages.pop()
        if (question && answer) {
          this.text = ''
          this.messages.push(answer)
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.chat-container {
  .container-binds-select {
    height: 3vw !important;
    box-shadow: none !important;
  }
}

.chat-filters {
  display: flex;
  background: white;
  padding: 10px;
  flex-wrap: wrap;
  gap: 9px;
  border-radius: 12px;

  .filter-select {
    flex: 1;
    height: 2.3rem;
    gap: 6px;

    .selected-option {
      font-size: 1vw !important;
      line-height: normal !important;
    }
  }

  .container-date-picker {
    .content-date-picker {
      width: 100%;
      height: 100%;

      .section-date-picker {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        border: 0.1rem solid #cacaca;
        font-size: 1rem;
        color: #808080;
        border-radius: 0.3rem;
        gap: 6px;
        background: white;
        align-items: center;

        .start-date,
        .end-date {
          font-size: 1rem;
          display: flex;
          align-items: center;
        }

        .icon-calendar {
          font-size: 1rem;
          color: var(--accent-color);
        }
      }
    }
  }
}

.date-range-buttons {
  padding: 0.5em;
  display: flex;

  button {
    height: 2.2em;
    padding: 0.3em;
    font-size: 1em;
    margin: 0.4em;
  }
}

.date-filter-container {
  min-width: 100%;
  display: flex;
  align-items: center;
}
</style>
<style lang='scss' scoped>
.v-enter-active {
  animation-duration: 200ms;
  @extend %fadeIn;
}

.chat-filters {
  display: flex;
  background: white;
  padding: 10px;
  flex-flow: column;
  gap: 9px;
  position: absolute;

  .container-date-picker {
    width: 14vw;
    height: 2.4rem;

    .content-date-picker {
      width: 100%;
      height: 100%;

      .section-date-picker {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        border: 0.1rem solid #cacaca;
        font-size: 1rem;
        color: #808080;
        border-radius: 0.3rem;
        gap: 6px;
        background: white;
        align-items: center;

        .start-date,
        .end-date {
          font-size: 0.9rem;
          display: flex;
          align-items: center;
        }

        .icon-calendar {
          font-size: 1rem;
          color: var(--accent-color);
        }
      }
    }
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;

  .current-chat-header {
    width: 95%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
    padding: 0 10px;
    padding-right: 2.5vw;
    .icon-show-list {
      cursor: pointer;
      font-size: 1.2vw;
      padding: 0.35vw;
      border-radius: 5px;
    }

    .title-current-survey {
      font-size: 1vw;
      font-weight: 600;
    }

    .icon-filter-current-chat {
      cursor: pointer;
      font-size: 1.3vw;
      padding: 0.35vw;
      border-radius: 5px;
    }

    .icon-show-list:hover, .icon-filter-current-chat:hover {
      background: #3f335620;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .header-info {
    flex: 1;
  }

  .intelligence-chat-title {
    font-size: 1rem;
    font-weight: bold;
  }

  .status {
    font-size: 0.85rem;
    color: #4a76a8;
  }

  .chat-actions {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(202, 202, 202);
    color: gray;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

    .count-filters {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5px;
      height: 5px;
      padding: 10px;
      border-radius: 50%;
      border: 1px solid #cacaca;
    }
    i {
      color: #4a76a8;
    }
  }

  .chat-messages {
    background-image: url('./images/wallpaper/5.jpg');
    background-size: cover;
    background-position: center;
    padding: 1vw;
    background-color: #e9eef4;
    overflow-y: auto;
    width: 100%;
    height: 91vh;
    padding-bottom: 2.5vw;
    position: relative;
    transition: width 2s;
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }

  .message {
    display: flex;
    align-items: flex-start;
  }

  .message-sent {
    flex-direction: row-reverse;
  }

  .message-received {
    flex-direction: row;
  }

  .message-content {
    position: relative;
    width: fit-content;
    max-width: 85%;
    padding: 10px 15px;
    background-color: #d4e6ff;
    border-radius: 15px;
    color: #333;
    font-size: 0.9rem;
    line-height: 1.7em;
    .message-options {
      position: absolute;
      left: 0;
      display: flex;
      z-index: 1;
      gap: 10px;
      bottom: -35px;
      padding: 10px 0px;
    }

    .icon {
      padding: 2px;
      cursor: pointer;
      .icon-active {
        color: #4a76a8 !important;
      }
      i {
        font-size: 1.2em;
        color: #949494;
      }
      i:hover {
        color: #4a76a8;
      }
    }
  }

  .message-sent .message-content {
    background-color: #b3d4fc;
    color: #000;
    border-radius: 15px 0px 15px 15px;
  }

  .message-received .message-content {
    border-radius: 0px 15px 15px 15px;
  }

  .message-time {
    font-size: 0.75rem;
    color: #999;
    margin-top: 5px;
    margin-left: 5px;
    align-self: flex-end;
  }

  .message-input {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
  }

  .message-input i {
    font-size: 24px;
    color: #4a76a8;
    margin-right: 10px;
    cursor: pointer;
  }

  .chat-input {
    flex: 1;
    padding: 15px;
    min-height: 30px;
    max-height: 100px;
    overflow-y: auto;
    background-color: #f0f2f5;
    border-radius: 20px;
    outline: none;
    font-size: 0.9rem;
    color: #333;
    overflow-y: auto;
    height: auto;
    line-height: 1.5vw;
  }

  .send-icon {
    font-size: 24px;
    color: #4a76a8;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
