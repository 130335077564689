<template>
  <div class="container-main-menu" v-if="!tvDashPage">
    <div class="content-image-account">
      <img
        class="image-main-menu"
        :src="user.account.theme.mainLogo"
        :style="{'mix-blend-mode': user.account.theme.mixBlendMode ? 'multiply' : '' }"
        alt="Logo"
      >
    </div>
    <div class="content-items-main-menu">
      <div class="items-main-menu" v-for="(item, idx) in items" :key="idx" @mouseleave="item.opened = false">
        <router-link v-if="!item.subitems"
          :to="{ path: item.link, params: item.params }"
          class="item-main-menu">
          {{item.label}}
        </router-link>
        <a class="item-main-menu" v-else-if="item.subitems" v-on:mouseover="showSubmenu(item)" :class="{'router-link-active': item.active}">
          {{ item.label }}
          <i class="material-icons icon-expand-menu" v-if="item.opened">expand_less</i>
          <i class="material-icons icon-expand-menu" v-else>expand_more</i>
        </a>
        <transition v-if="item.subitems" name="dropdown">
          <div class="dropdown-main-menu" :mouseover="showSubmenu(item)" v-if="item.opened">
            <ul class="dropdown-main-list">
              <li class="dropdown-main-item" v-for="(subitem, index) in item.subitems" :key="index">
                <p class="dropdown-submenu-item" :class="{'item-main-menu-active': subitem.active}" @click="changeSelected(subitem, index, item)">
                  <i class="material-icons icon-sub-selected" v-if="subitem.active">arrow_right</i>
                  <span>{{ subitem.label }}</span>
                  <i class="material-icons icon-sub-selected" v-if="subitem.active">arrow_left</i>
                </p>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div v-if="canSeeIntelligence" class="items-main-menu" style='cursor: pointer;'>
        <span @click="handleClickOnMenu()" class="item-main-menu">Binds Intelligence</span>
        <i class="material-icons icon-new-feature">fiber_new</i>
      </div>
    </div>
    <div class="content-user-account">
      <app-menu-dropdown class="dropdown-min" v-if="user.name" :label="user.name" :items="submenuItems">
        <template v-slot:icon>
          <div class="accountLogo">
            <img :src="returnUserImageProfile" alt="logo">
          </div>
        </template>
        <template v-slot:item-structure="{item}">
          <span v-if="item.action !== 'open-email-help'" class="item-sub-menu" v-on:click="$root.$emit(item.action)">{{item.label}}</span>
          <span v-else-if="item.action === 'open-email-help'">
            <a :href="`mailto:atendimento@binds.co?subject=${encondedSub}`">{{ item.label }}</a>
          </span>
        </template>
      </app-menu-dropdown>
    </div>
    <ModalAutomaticMessage v-if="openAutoMessage" @close-modal-auto-message="openAutoMessage = false" />
    <AccountConfig v-if="openAccountConfig" :class="{'expand-profile': openAccountConfig}"/>
    <MyProfile v-if="showProfile" :class="{'expand-profile': showProfile}"/>
    <Metadata v-if="showMetadata" :class="{'expand-profile': showMetadata}"/>
    <SendPreferences v-if="showSendPreferences" :class="{'expand-profile': showSendPreferences}"></SendPreferences>
    <theme-style>
      <!-- Menu superior -->
      .primaryBackground,
      .spinner-inner .load3::after,
      .spinner-inner .load3::before,
      .spinner-inner .load3-center {
        background-color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
      }
      .vs__open-indicator {
        fill: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      .text-field__input input {
        color: black !important;
      }
      .menu__item > a:hover,
      .menu__item  > div:hover,
      .isMobileMenu > a, .isMobileMenu,
       .menu__item > .router-link-active,
       .headerText {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .menu__item  > a,
      .menu__item  > div,
      .seta {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c87' : '#fbfbfb9c' }};
        border-color: {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c87' : '#fbfbfb9c' }};
        font-family: inherit;
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
      }
      #v1Text {
        background: {{user.account.theme.colors.accent || '#a98ed1'}} !important;
      }
      #v1Text:after {
        border-right-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      #changeToV1:hover, #v1Text:hover #changeToV1 {
        color: {{user.account.theme.colors.accent || '#a98ed1'}} !important;
      }

      <!-- start loading animation color  -->
      .double-bounce1 {
        background-color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      .double-bounce2 {
        background-color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      <!-- end of loading animation color  -->

      <!-- Dropdown do Menu superior (dados da conta) -->
      .dropdown-menu__label__icon  {
        border-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .dropdown-menu__label__text {
        color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .dropdown-menu__list {
        border-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      <!-- dropdown do dashboard -->
      .dropdown__menu-item--active {
        color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .dropdown__menu-item > div:hover,
      .dropdown__menu-item > div:focus {
        color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .menu__brand > .heading {
        color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      <!-- start color icons -->
      .tvDashButtomColorActive  div i,
      .fullScreenButtomColorActive .button-icon .b-icon,
      .tvDashButtomColorActive,
      .fullScreenButtomColorActive {
        color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      <!-- end color icons -->
      <!-- Close buttons in settings sidebars -->
      .close-config > button > span {
        color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
      }
      <!-- configs for sendPreferences settings -->
      .interval > label > .text-field__input > input {
        color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
      }
      <!-- menu mobile com efeitos de quadrados -->
      .link-active {
        color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      .ui-switch.checked .ui-switch-thumb {
        background-color: {{user.account.theme.colors.accent || '#673ab7'}} !important;
      }
      <!-- start classes padrões para botões  -->
      .btn-icons {
        color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .btn-circle-accent {
        background-color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
        box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.accent || '#ff607d' }} !important;
        color: {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .btn-light-accent {
        background-color: {{ user.account.theme.colors.accent || '#ff607d' }} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        border-color: {{ user.account.theme.colors.accent || '#ff607d'}} !important;
        box-shadow: 0 5px 10px 0 {{ user.account.theme.colors.accent || '#ff607d' }} !important;
      }
      .btn-outline-secondary {
        background: #f000 !important;
        background-color: #f000 !important;
        color:{{ user.account.theme.colors.secondary || '#3f3356' }} !important;
        border-color: {{ user.account.theme.colors.secondary || '#3f3356' }} !important;
        box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.secondary || '#3f3356' }} !important;
      }
      .btn-outline-primary {
        background: #f000 !important;
        background-color: #f000 !important;
        color:{{ user.account.theme.colors.primary || '#673ab7' }} !important;
        border-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
        box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .btn-default-primary {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        border-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
        background-color:{{ user.account.theme.colors.primary || '#673ab7' }} !important;
        box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      <!-- end classes padroes para botoes -->

      <!-- start modal exceeded limit -->
      .content-exceeded .material-icons {
        color:{{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }

      <!-- start send hub page -->
      .active-type-send,
      .alert__content--pink-light  {
        background-color: {{ user.account.theme.colors.accent || '#ff607d' }} !important;
      }
      .headerSendhub,
      .headerSendhub .text-field__input input {
        background-color:{{ user.account.theme.colors.secondary || '#3f3356' }} !important;
      }
      .sms-link {
        background-color: {{ user.account.theme.colors.secondary || '#3f3356' }} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      <!-- end send hub page -->
      <!-- start classes padroes edição de pesquisa -->
      .navSelectorIsActive i,
      .navSelectorIsActive span,
      .VueCarousel-navigation button i {
        color: {{ user.account.theme.colors.accent || '#ff607d' }} !important;
      }
      <!-- end classes padroes edição de pesquisa -->
      <!-- start classes padrões para modal -->
      .modal-container {
        border-top: 9px solid {{ user.account.theme.colors.primary || '#673ab7' }} !important;
      }
      .modal-default-primary > .modal__wrapper > .modal__wrapper__actions__button > div {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .modal-default-primary > .modal__wrapper::before {
        background-color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
      }
      .modal-default-primary > .modal__wrapper > .modal__wrapper__title {
       color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
      }
      .modal-default-primary > .modal__wrapper > .modal__wrapper__actions > button,
      .modal__wrapper__actions .button, .modal__wrapper__actions .button.secondary.outline,{
        border-color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
        background-color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
        box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.primary|| '#673ab7'}} !important;
      }
      .modal__wrapper__actions__button {
        background-color: {{ user.account.theme.colors.accent || '#ff607d' }} !important;
        border: 1px solid {{ user.account.theme.colors.accent || '#ff607d' }} !important;
        box-shadow: 0 5px 10px 0 #dddcdc !important;
      }
      .modal__wrapper__actions__button.outline {
        background-color: transparent !important;
        color: {{ user.account.theme.colors.accent || '#ff607d'}} !important;
        border-color: {{ user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      <!-- end classes padroes para modal -->
    </theme-style>
  </div>
</template>
<script>
import ModalAutomaticMessage from './tickets/ModalAutomaticMessage.vue'
import { mapActions, mapGetters } from 'vuex'
import AccountConfig from './account/accountConfig.vue'
import MyProfile from './user/MyProfile.vue'
import Metadata from './user/Metadata.vue'
import SendPreferences from './send/SendPreferences.vue'
import { accountService } from '@/_services'
import { responseOptions, constants } from '@/_helpers'
import intelligenceMixin from '@/_mixins/intelligence'

export default {
  name: 'Menu',
  components: {
    AccountConfig,
    MyProfile,
    Metadata,
    SendPreferences,
    ModalAutomaticMessage,
    'app-menu-dropdown': () => import('@binds-tech/binds-design-system/src/components/Menu/Dropdown')
  },
  mixins: [intelligenceMixin],
  data () {
    return {
      name: '',
      openAutoMessage: false,
      openAccountConfig: false,
      showProfile: false,
      showMetadata: false,
      showSendPreferences: false,
      openMenu: false,
      show: false,
      tvDashPage: false,
      items: [
        { link: '/realtime', label: this.$i18n.t('dashboard.real_time'), active: false, disabled: false, name: 'RealTime' },
        { link: '/survey-list', label: this.$i18n.t('menu.surveys'), active: false, showMobile: true, name: 'SurveysList' },
        {
          link: '/dashboard/overview/NaN',
          label: this.$i18n.t('menu.reports'),
          showMobile: true,
          active: false,
          opened: false,
          subitems: [
            { link: 'dashboard/overview', class: 'col-xs-3', label: this.$i18n.t('dashboard.overview'), icon: 'dashboard', active: false, disabled: false, name: 'Overview', id: 0 },
            { link: 'dashboard/individual', class: 'col-xs-3', label: this.$i18n.t('dashboard.individual_responses'), icon: 'vertical_split', active: false, disabled: false, name: 'Individual', id: 1 },
            { link: 'dashboard/referrals', class: 'col-xs-3', label: this.$i18n.t('dashboard.referrals'), icon: 'share', active: false, disabled: false, name: 'Referrals', id: 2 },
            { link: 'dashboard/ranking', class: 'col-xs-3', label: this.$i18n.t('dashboard.ranking'), icon: 'show_chart', active: false, disabled: false, name: 'Ranking', id: 3 },
            { link: 'dashboard/comparative-dashboard', class: 'col-xs-3', label: this.$i18n.t('dashboard.performance'), icon: 'show_chart', active: false, disabled: false, name: 'Performance', id: 4 },
            { link: '/approaches', class: 'col-xs-3', label: this.$i18n.t('menu.approaches'), active: false, disabled: false, name: 'Approaches', id: 5 }
          ]
        },
        {
          link: '/text-analytic',
          label: this.$i18n.t('menu.textual_analysis'),
          active: false,
          opened: false,
          subitems: [
            { link: 'text-analytic/cloud-words', class: 'col-xs-3', label: this.$i18n.t('dashboard.word_cloud'), icon: 'dashboard', active: false, disabled: false, name: 'Analytics', id: 0 },
            { link: 'sentimental', class: 'col-xs-3', label: this.$i18n.t('pageTitles.sentimental'), icon: 'dashboard', active: false, disabled: false, name: 'Sentimentals', id: 2 }
          ]
        },
        { link: '/insights', label: 'Insights & Reports', active: false, disabled: false, name: 'Insights' },
        { link: '/tickets', label: this.$i18n.t('menu.tickets'), active: false, disabled: false, name: 'Tickets' }
      ],
      encondedSub: ''
    }
  },
  created () {
    // tawk.to
    const sub = `${this.$i18n.t('menu.help_sub')} ${this.user.account.name}, ${this.$i18n.t('menu.help_user')} [${this.user._id}]`
    this.encondedSub = encodeURI(sub)
    this.items.forEach((item, idx) => {
      if (!item.subitems && item.name === this.$route.name) {
        item.active = true
      }

      if (item.subitems) {
        item.subitems.forEach(subItem => {
          if (subItem.name === this.$route.name) {
            subItem.active = true
            item.active = true
          }
        })
      }
    })

    const find = this.items.findIndex(e => e.name === 'SocialMedia')
    if (find === -1 && this.account.account.termsSettings.enabled) {
      this.items.push({ link: '/social', style: 'max-width: 10% !important;text-align: left;', label: 'BVS', active: false, disabled: false, name: 'SocialMedia' })
      this.items.splice(find, 1)
    }

    const tickets = this.items.findIndex(e => e.name === 'Tickets')
    if (tickets > -1 && this.user.features.hiddenSendingContacts) {
      this.items.splice(tickets, 1)
    }

    const insights = this.items.findIndex(e => e.name === 'Insights')
    if (this.user.type === 'user' && !this.user.features.insights) {
      this.items.splice(insights, 1)
    }

    const subitems = this.items.findIndex(e => e.subitems)
    if (subitems && !this.user.features.ranking) {
      const ranking = this.items[subitems].subitems.findIndex(e => e.name === 'Ranking')
      this.items[subitems].subitems.splice(ranking, 1)
    }

    this.$root.$on('open-history-resend', () => {
      this.$router.push({ name: 'ResendList' }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
    })

    this.$root.$on('open-auto-message', () => {
      this.openAutoMessage = true
    })
    this.$root.$on('tv-dash-page', () => {
      this.tvDashPage = !this.tvDashPage
    })
    this.$root.$on('open-account-config', () => {
      this.openAccountConfig = true
    })
    this.$root.$on('open-send-preferences-config', () => {
      this.showSendPreferences = true
    })
    this.$root.$on('open-profile-config', () => {
      this.showProfile = true
    })
    this.$root.$on('open-metadata-config', () => {
      this.showMetadata = true
    })
    this.$root.$on('close-account-config', () => {
      this.openAccountConfig = false
    })
    this.$root.$on('close-profile-config', () => {
      this.showProfile = false
    })
    this.$root.$on('close-send-preferences-config', () => {
      this.showSendPreferences = false
    })
    this.$root.$on('close-metadata-config', () => {
      this.showMetadata = false
    })
    this.$root.$on('open-roles', () => {
      this.$router.push({ name: 'Roles' }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
    })
    this.$root.$on('open-activity', () => {
      this.$router.push({ name: 'Activity' }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
    })
    this.$root.$on('open-chat', () => {
      if (window.Tawk_API) {
        window.Tawk_API.start({
          showWidget: true
        })
        window.Tawk_API.maximize()
        window.Tawk_API.setAttributes({
          pagante: this.user.account.billing.hasInvoice ? 'Sim' : 'Não',
          name: this.user.name,
          email: this.user.email,
          hash: this.hashMe(this.user.name + this.user.email + this.user._id),
          empresa: this.user.account.name
        }, function (error) {
          console.log(error)
        })
      }
    })
    this.$root.$on('logout', () => {
      // this.logout() old way
      this.$store.commit('account/updateProfile', { _id: null })
      this.$router.push({ name: 'Login' }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
      const isChatHidden = window.Tawk_API.isChatHidden()
      if (!isChatHidden) {
        window.Tawk_API.hideWidget()
      }
    })
    this.$root.$on('open-account-users', () => {
      this.$router.push({ name: 'Users' }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
    })
    this.$root.$on('open-list-of-accounts', () => {
      this.$router.push({ name: 'Accounts' }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
      !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.log(err)
        }
      })
    })
    this.$root.$on('redirect-to-responses', (obj) => {
      const query = this.$route.query.q
      const queryByOptions = responseOptions.mountQueryToRedirect(obj.question, obj.clickedOption, query)
      this.$router.push({ name: 'Individual', query: { q: queryByOptions } }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
    })

    this.$root.$on('redirect-responses-health', payload => {
      this.$router.push({ name: 'Individual', query: { q: payload } }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
    })
  },
  mounted () {
  },
  computed: {
    filtersInsight () { return this.$store.getters['filtersInsight/getFilters'] },
    account () { return this.$store.getters['account/getUser'] },
    submenuItems () {
      let items = [
        { link: '', label: this.$i18n.t('menu.my_profile'), active: false, action: 'open-profile-config' },
        { link: '', label: this.$i18n.t('menu.my_account'), active: false, action: 'open-account-config' },
        { link: '', label: this.$i18n.t('menu.user_role'), active: false, action: 'open-account-users' },
        { link: '', label: this.$i18n.t('menu.accounts'), active: false, action: 'open-list-of-accounts' },
        { link: '', label: this.$i18n.t('menu.metadata'), active: false, action: 'open-metadata-config' },
        { link: '', label: this.$i18n.t('menu.send_setting'), active: false, action: 'open-send-preferences-config' },
        { link: '', label: this.$i18n.t('menu.users_group'), active: false, action: 'open-roles' },
        { link: '', label: this.$i18n.t('resend.title_schedule'), active: false, action: 'open-history-resend' },
        { link: '', label: this.$i18n.t('menu.auto_messages'), active: false, action: 'open-auto-message' },
        { link: '', label: this.$i18n.t('activity.title'), active: false, action: 'open-activity' },
        [
          { label: this.$i18n.t('menu.help_chat'), action: 'open-chat' },
          { label: this.$i18n.t('menu.help_email'), action: 'open-email-help' },
          { label: this.$i18n.t('menu.exit'), action: 'logout' }
        ]
      ]

      if (['admin', 'superadmin'].indexOf(this.account.type) === -1) {
        items = items.filter(i => {
          if (['open-account-config', 'open-metadata-config', 'open-send-preferences-config', 'open-roles', 'open-account-users', 'open-activity'].indexOf(i.action) === -1) {
            return i
          }
        })

        if (this.user.type === 'user' && (!this.user.features.tickets || this.user.features.hiddenSendingContacts)) {
          const index = items.findIndex(i => i.action === 'open-auto-message')
          if (index > -1) { items.splice(index, 1) }
        }

        if (this.user.type === 'user' && !this.user.features.seedBatches) {
          const index = items.findIndex(i => i.action === 'open-history-resend')
          if (index > -1) { items.splice(index, 1) }
        }

        let subSupport = ''
        items.forEach((o, index) => {
          const getSupportLink = Array.isArray(o)
          let currentIndex = ''
          if (getSupportLink) {
            currentIndex = index
            subSupport = o.filter(p => { return p.action !== 'open-chat' && p.action !== 'open-email-help' })
          }
          if (!this.account.features.bindsSupport) {
            items[currentIndex] = subSupport
          }
        })
      }
      if (this.account.type !== 'superadmin') {
        const index = items.findIndex(i => i.action === 'open-list-of-accounts')
        if (index > -1) {
          items.splice(index, 1)
        }
      }
      return items
    },
    returnUserImageProfile () {
      return this.user.account.theme && this.user.image && this.user.image.value ? this.user.image.value : constants.user.USER_DEFAULT_IMAGE
    },
    logo () {
      return require('../assets/img/logo.svg')
    },
    userAvatar () {
      return require('../assets/img/avatar.jpg')
    },
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    handleClickOnMenu () {
      return this.toggleSidebar(true)
    },
    hashMe (str) {
      var hash = 0
      var i
      var chr
      if (str.length === 0) return hash
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    },
    async changeToV1 () {
      const user = this.$store.getters['account/getUser']
      const account = this.$store.getters['account/getAccount']
      const response = await accountService.loginFromV1({ user: user._id, account: account._id })
      if (response.user) {
        const token = window.localStorage.getItem('bindsUser') ? JSON.parse(window.localStorage.getItem('bindsUser')) : ''
        const url = `${process.env.VUE_APP_V1}login/authenticationWithSSO/setToken?token=${token}`
        window.location.href = url
      }
    },
    openMobileMenu () {
      this.openMenu = !this.openMenu
    },
    changeSelected (item, index, itemFather) {
      this.$route.query.page = 1
      if (!this.$route.query.q) {
        this.$route.query.q = JSON.stringify({ $and: this.$store.getters['filters/getDashFilter'].query.$and })
      }

      this.$router.push({ path: '/' + item.link, query: { q: this.$route.query.q } }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })

      if (item.name === 'Performance') {
        this.$router.push({ path: '/' + item.link }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
      }

      if (item.name === 'Approaches') { this.$router.push({ name: 'Approaches' }) }
    },
    showSubmenu (item) { item.opened = true },
    ...mapActions('account', ['logout']),
    async linkActions (event, action) {
      if (action === 'logout') { await this.logout() }
    }
  }
}
</script>

<style lang="scss">
.container-main-menu {
  width: 100%;
  height: 4vw; // não me orgulho disso.
  background: var(--primary-color);
  padding: 7px;
  border-bottom: 0.1rem solid #e5e5e54a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content-image-account {
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-main-menu {
      max-width: 10vw;
      height: 100%;
      object-fit: contain;
    }
  }
  .content-items-main-menu {
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .items-main-menu {
      position: relative;
      align-items: center;
      .icon-new-feature {
          position: absolute;
          right: -10%;
          top: -40%;
          font-size: 1.6vw;
          color: var(--accent-color);
        }
      .item-main-menu {
        position: relative;
        text-decoration: none;
        font-size: 0.85vw;
        line-height: 2vw;
        font-weight: bold;
        color: var(--default-text-color);
        opacity: 0.7;
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
      .item-main-menu:hover {
        opacity: 1;
      }
      .dropdown-main-menu {
        position: absolute;
        top:  95%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 99999;
        background: #ffffff;
        border: 0.1rem solid #e1e1e1;
        border-radius: 0.3rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        .dropdown-main-list {
          min-width: 10rem;
          .dropdown-main-item {
            font-weight: 300;
            border-bottom: 0.1rem solid #eaeaea;
            .dropdown-submenu-item {
              padding: 0.8rem 0;
              font-size: 0.8vw;
              color: #808080;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                width: 8.5rem;
                text-align: center;
              }
              .icon-sub-selected {
                color: var(--secondary-color);
              }
            }
            .dropdown-submenu-item:hover {
              color: var(--secondary-color);
              cursor: pointer;
              span {
                transform: scale(1.02);
              }
            }
          }
          .dropdown-main-item:last-child {
            border: none;
          }
        }
      }
    }
    .router-link-active {
      color: var(--default-text-color) !important;
      opacity: 1 !important;
    }
    .item-main-menu-active {
      color: var(--secondary-color) !important;
      font-weight: 500;
    }
  }
  .content-user-account {
    z-index: 35;
    width: 15%;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown-min {
      min-width: 90%;
      max-width: 100%;
      .dropdown-menu__label {
        width: 100%;
        height: 2.4vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        border: 2px solid #e1e1e1;
        border-left: none;
        .dropdown-menu__label__icon {
          position: static;
          width: 2.7vw;
          height: 2.7vw;
          border-color: #e1e1e1;
          .accountLogo {
            width: 100%;
            height: 100%;
            img {
              object-fit: fill;
            }
          }
        }
        .dropdown-menu__label__text {
          color: #454548;
          width: 72%;
          font-size: 0.8vw;
          display: flex;
          align-items: center;
          justify-content: left;
        }
      }
      .dropdown-menu__list {
        border-color: #e1e1e1;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        padding: 2rem 0 0 0;
        .dropdown-menu__list__item {
          padding: 0;
          height: 2rem;
          cursor: pointer;
          color: #454548;
          font-weight: 300;
          font-size: 0.9vw;
          display: flex;
          .item-sub-menu {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
          }
        }
        .dropdown-menu__list__item:hover {
          color: var(--secondary-color);
        }
        li {
          .dropdown-menu__sublist {
            .dropdown-menu__sublist__item {
              padding: 0.8rem 0.5rem;
              cursor: pointer;
              color: #454548;
              font-weight: 300;
              font-size: 0.9vw;
              border-bottom: 0.1rem solid #e1e1e1;
            }
            .dropdown-menu__sublist__item:last-child {
              border: none;
            }
            .dropdown-menu__sublist__item:hover {
              span {
                color: var(--secondary-color);
                a {
                  color: var(--secondary-color);
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdown__menu {
  -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08);
  box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(243, 243, 243, 0.521);
  border-radius: 6px;
  background-color: white;
}

.expand-profile {
  @extend %animated-faster;
  @extend %fadeInRight;
}
</style>
