<template>
  <div id="app" :style="cssVars">
    <router-view v-if="!isLoading"/>
    <!-- <app-footer /> -->
    <Alert />
    <ExceededLimit v-if="user._id"/>
    <IntelligenceChat  v-if="user._id && canSeeIntelligence"/>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import packageData from '../package.json'
import Alert from './components/alerts/Alerts.vue'
import { mapGetters } from 'vuex'
import intelligenceMixin from '@/_mixins/intelligence'
import ExceededLimit from './components/exceeded/ExceededLimit.vue'
import IntelligenceChat from './components/SideBarConfig/IntelligenceChat.vue'
import WebFont from 'webfontloader'

export default {
  name: 'app',
  mixins: [intelligenceMixin],
  components: { Alert, ExceededLimit, IntelligenceChat },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    cssVars () {
      return {
        '--accent-color': this.user.account.theme.colors.accent || '#64469d', // good for details
        '--primary-color': this.user.account.theme.colors.primary || '#673ab7', // primary color for menu and some buttons
        '--secondary-color': this.user.account.theme.colors.secondary || '#3f3356', // back color for edit survey breadcrumb and some buttons
        '--default-text-color': this.user.account.theme.colors.primaryText === 'dark' ? '#302b3c' : '#fdfbff' // dark and white texts based on account flag
      }
    }
  },
  data () {
    return {
      isLoading: true
    }
  },
  created () {
  },
  async mounted () {
    const versionKey = 'binds_current_version'
    const currentReload = localStorage.getItem(versionKey)
    if (currentReload !== packageData.version) {
      localStorage.setItem(versionKey, packageData.version)
      window.location.reload()
    }
    const vm = this
    WebFont.load({
      custom: {
        families: ['icomoon', 'bicons']
      },
      inactive: function () { vm.isLoading = false },
      active: function () { vm.isLoading = false },
      timeout: 30
    })
    // importe o seu javascript aqui =)

    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    document.head.appendChild(recaptchaScript)
    const googleadservices = document.createElement('script')
    googleadservices.setAttribute('src', 'https://www.googleadservices.com/pagead/conversion_async.js')
    document.head.appendChild(googleadservices)
    // manter tawk.to desativo caso o usuario for pagante ou for superuser
    const userTawk = this.user
    const route = this.$route
    const forbiddenTawktPages = ['Login', 'ConfirmResetPass']
    const freePlan = !userTawk?.account?.billing?.hasInvoice
    const chatAllowedOnThisPage = !forbiddenTawktPages.includes(route.name)
    window.Tawk_API.onBeforeLoad = function () {
      window.Tawk_API.autoStart = false
      if (freePlan && chatAllowedOnThisPage) {
        window.Tawk_API.autoStart = true
      }
      // always hide the widget before start, and then show it if needed on LOAD
      window.Tawk_API.hideWidget()
    }

    window.Tawk_API.onChatMinimized = function () {
      window.Tawk_API.hideWidget()
    }

    window.Tawk_API.onChatMaximized = function () {
      window.Tawk_API.start({
        showWidget: true
      })
    }

    window.Tawk_API.onLoad = function () {
      if (freePlan && chatAllowedOnThisPage) {
        window.Tawk_API.autoStart = true
        vm.$root.$emit('open-chat')
      }
    }
  },
  metaInfo: {
    link: [
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp|Material+Symbols+Outlined' }
    ]
  }
}
</script>

<style lang="scss">
@import '@/sass/_reset.scss';
@import '@/sass/global.scss';
@import '@/sass/hover-grow.scss';
@import '@/sass/keyframes.scss';
@import '@/sass/pagination.scss';
@import "vue-select/src/scss/vue-select.scss";

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .footer{
    margin-top: 40px;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
.wave-container {
  height: 100vh;
}
.inputFieldForm {
  display: flex;
  padding: 0.7vw;
}
.inputFieldForm > input {
  width: 85%;
  border: none;
  color: #ed4d7f;
  border-bottom: 1px solid #dfdfdf;
  font-size: 1vw;
}
.inputFieldForm > i {
  font-size: 1.8vw;
  padding: 0.5vw;
}
#listOfBindsResources {
  color: rgb(255, 255, 255);
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 1.88vw;
  position: absolute;
  top: -3vw;
  left: 1.5vw;
  line-height: 3.5vw;
  z-index: 1;
}
#listOfBindsResources > li > i {
  color: #fff !important;
  padding: 0.6vw;
  font-size: 1.6vw;
}
#listOfBindsResources > li > span {
  font-size: 1.5vw;
}
@font-face {
  font-family: 'icomoon';
  src:url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/icomoon.eot?-bkw6sq');
  src:url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/icomoon.eot?#iefix-bkw6sq') format('embedded-opentype'),
  url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/icomoon.woff?-bkw6sq') format('woff'),
  url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/icomoon.ttf?-bkw6sq') format('truetype'),
  url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/icomoon.svg?-bkw6sq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bicons';
  src: url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.eot');
  src: url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.svg') format('svg'),
    url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
::-webkit-scrollbar-track
{
  border-radius: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar
{
  width: 5px;
  height: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
border-radius: 10px;
background-color: $color-purple-darker;
}
input[type=time] {
  border: none;
  color:#64469d;
  font-size: 14px;
  font-family: Open Sans,Helvetica,Arial,sans-serif;
  width: 125px;
}

input[type=time]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

input[type=time]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

/* Hour */
input[type=time]::-webkit-datetime-edit-hour-field {
  background-color: #ffff;
  border-radius: 6px;
  padding: 10px;
  height: 28px;
  border: 2px solid #b5b5b5;
}

/* Minute */
input[type=time]::-webkit-datetime-edit-minute-field {
  background-color: #ffff;
  border-radius: 6px;
  padding: 10px;
  height: 28px;
  border: 2px solid #b5b5b5;
}
input[type=time]::-webkit-datetime-edit-hour-field:focus,
input[type=time]::-webkit-datetime-edit-minute-field:focus {
  color:#fff;
  background-color:#64469d;
}

/* Container box to set the sides relative to */
.cube-transition {
width: 90%;
margin: 0 auto;
height: 30px;

-webkit-transition: -webkit-transform .33s;
transition: transform .33s; /* Animate the transform properties */

-webkit-transform-style: preserve-3d;
transform-style: preserve-3d; /* <-NB */
}

/* The two faces of the cube */
.cube-flippety,.cube-flop {
height: 35px;
display: block;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
font-size: 15px !important;
white-space: nowrap;
}

/* Position the faces */
.cube-flippety {
-webkit-transform: translateZ(20px);
transform: translateZ(20px);
}

.cube-flop {
border: none;
-webkit-transform: rotateX(-90deg) translateZ(-50px);
transform: rotateX(-90deg) translateZ(-50px);
opacity: 0;
}

/* Rotate the cube */
.cube-transition:hover {
-webkit-transform: rotateX(89deg);
transform: rotateX(89deg); /* Text bleed at 90º */
  .cube-flippety {
   opacity: 0;
  }
  .cube-flop {
    opacity: 1;
  }
}
</style>
