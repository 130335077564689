<template>
  <div class="new-chat-intelligence">
    <loadingSpinner v-if='showLoading'> </loadingSpinner>
    <section class="new-chat-header" v-if="!intelligence.showChatList">
      <!-- BUTTON HIDE/SHOW LIST -->
      <i class="material-icons-outlined icon-show-list"
        v-title="$t('intelligence.tooltips.chat_view')"
        title-placement="top"
        @click="toggleChatList()"
      >vertical_split</i>
    </section>

    <!-- Chat Messages -->
    <div class="chat-messages" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index"
        :class="['message', message.type === 'sent' ? 'message-sent' : 'message-received']">
        <!-- Avatar -->
        <div class="container-message-bot">
          <div v-if="message.type === 'received'" class="section-icon-binds-intelligence">
            <img class="avatar" src="./images/avtar/open-graph.png" alt="avatar" />
          </div>
          <!-- Message Text -->
          <div class="message-content" v-html="message.htmlText"></div>
        </div>

        <div class="content-questions-default" v-if="questionsDefault.length && botCurrentQuestion.type === 'text'">
          <div class="section-questions-default">
            <p class="title-questions-default">{{ $t('intelligence.suggestions') }}:</p>
            <button
              class="question-default"
              v-for="template in questionsDefault"
              :key="template._id"
              @click="sendTemplate(template)"
              >{{ template.question }}
            </button>
          </div>
        </div>

        <!-- Waiting For User Input On -->
        <div v-if="!done" class="container-inputs-bot">

          <div v-if="botCurrentQuestion && index === botCurrentQuestion.index" class="question-input">

            <!-- Text Input -->
            <div v-if="botCurrentQuestion.type === 'text'">
              <div class="message-input">
                <div ref="botInput" contenteditable="true" class="chat-input" @keydown.enter="nextQuestion()"
                  @input="updateText">
                </div>
                <i class="material-icons send-icon" @click="nextQuestion()">send</i>
              </div>
            </div>

            <!-- Select Input -->
            <div v-else-if="botCurrentQuestion.type === 'select'" class="section-input-bot">
              <app-select-binds
                v-if="botCurrentQuestion.field === 'surveyQuestion.question'"
                class="binds-selector-chat"
                :placeholder="$t('intelligence.chat.placeholders.question')"
                :titleItem="'question'"
                id="questions-to-chat"
                :showIndex="true"
                :options="surveyQuestions"
                @input="nextQuestion()"
                :value="newChat.surveyQuestion"
                @model="newChat.surveyQuestion = $event"
              />
              <app-select-binds
                v-if="botCurrentQuestion.field === 'survey.title'"
                class="binds-selector-chat"
                :placeholder="$t('intelligence.chat.placeholders.survey')"
                :options="surveys"
                :titleItem="'title'"
                id="surveys-to-chat"
                :showIndex="true"
                @input="nextQuestion(); changeSurveyAndSetQuestions($event)"
                :value="newChat.survey"
                @model="newChat.survey = $event"
              />
              <app-select-binds
                v-if="botCurrentQuestion.field === 'metadata.label'"
                class="binds-selector-chat"
                :placeholder="$t('intelligence.chat.placeholders.metadata')"
                :options="account.fields"
                :titleItem="'label'"
                id="meta-to-chat"
                :showIndex="true"
                @input="nextQuestion()"
                :value="newChat.metadata"
                @model="newChat.metadata = $event"
              />
            </div>

            <!-- Date Input -->
            <div v-else-if="botCurrentQuestion.type === 'date'" class="section-input-bot">
              <v-date-picker class="container-date-picker" v-model="newChat.date" color="gray" is-range locale="pt-br"
                mode="date" @input="changeDate">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="content-date-picker">
                    <div class="section-date-picker" v-on="inputEvents.start">
                      <div class="start-date">
                        <span>{{ inputValue.start }}</span>
                      </div>
                      <p>-</p>
                      <div class="end-date">
                        <span>{{ inputValue.end }}</span>
                      </div>
                      <i class="material-icons icon-calendar">calendar_month</i>
                    </div>
                  </div>
                </template>
                <template #footer>
                  <div style="padding: 0.5em;display: flex;">
                    <button @click="changeDate(mockDate)" v-for="(mockDate, index) in rangeOfDates" :key="index"
                      style="height: 2.2em; padding: 0.3em; font-size: 1em;margin: 0.4em;">{{
                        $t(`date_filter.periods.${mockDate}`) }}</button>
                  </div>
                </template>
              </v-date-picker>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from '@/_helpers'
import { chatService } from '@/_services'
import { mapGetters } from 'vuex'
import intelligenceMixin from '@/_mixins/intelligence'
import loadingSpinner from '@/components/chat/loading.vue'

export default {
  name: 'newChat',
  mixins: [intelligenceMixin],
  components: {
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds'),
    loadingSpinner
  },
  data () {
    return {
      questionsDefault: [],
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year'],
      messages: [],
      showLoading: false,
      botQuestions: [
        {
          index: 0,
          field: 'question',
          type: 'text',
          text: this.$t('intelligence.chat.description')
        }
      ],
      nextQuestions: [
        {
          index: null,
          field: 'survey.title',
          type: 'select',
          text: this.$t('intelligence.chat.inputs.survey')
        },
        {
          index: null,
          field: 'convertedDate',
          type: 'date',
          text: this.$t('intelligence.chat.inputs.date')
        }
      ],
      surveyQuestions: [],
      surveys: [],
      done: false,
      currentQuestionIndex: 0,
      newChat: {
        title: '',
        survey: '',
        question: '',
        surveyQuestion: '',
        metadata: '',
        date: {
          end: this.$moment().subtract(7, 'days').format(),
          start: this.$moment().format()
        },
        convertedDate: '',
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount'
    }),
    botCurrentQuestion () {
      return this.botQuestions[this.currentQuestionIndex] || null
    }
  },
  async mounted () {
    this.showLoading = true
    const [resultQuestionsDefault] = await Promise.all([
      chatService.getQuestionsDefault(),
      this.getSurveys()
    ])

    this.questionsDefault = resultQuestionsDefault

    this.messages.push({
      htmlText: this.convertToHtml(this.botCurrentQuestion.text),
      sender: 'AI Bot',
      type: 'received'
    })
    this.showLoading = false
  },
  methods: {
    async sendTemplate (template) {
      this.showLoading = true
      this.newChat.question = template.question
      await chatService.chosenTemplateDefault(template._id)
      this.nextQuestion()
    },
    async nextQuestion () {
      this.showLoading = true

      if (this.botCurrentQuestion.index === 0) {
        const { classification } = await chatService.getMessageClassification({
          question: this.newChat.question
        })

        if (classification === 'ranking') {
          this.nextQuestions.push({
            index: null,
            field: 'metadata.label',
            type: 'select',
            text: this.$t('intelligence.chat.inputs.metadata')
          })

          this.nextQuestions.push(
            {
              index: null,
              field: 'surveyQuestion.question',
              type: 'select',
              text: this.$t('intelligence.chat.inputs.question')
            })
        }

        if (classification === 'invalid') {
          this.showLoading = false
          this.$store.commit('alerts/alert', {
            message: this.$t('intelligence.chat.classification_error'),
            position: 'bottomRight',
            showAlert: true
          })
          return
        }
      }

      // show the user message
      this.messages.push({
        htmlText: this.convertToHtml(window._.get(this.newChat, this.botCurrentQuestion.field)),
        sender: 'You',
        type: 'sent'
      })

      const newIndex = this.currentQuestionIndex + 1
      if (this.nextQuestions[newIndex - 1]) {
        const questionToAsk = this.nextQuestions[newIndex - 1]
        const questionIndex = questionToAsk.index || this.botCurrentQuestion.index + 2
        this.botQuestions.push({ ...questionToAsk, index: questionIndex })
      }
      if (this.botQuestions[newIndex]) {
        this.messages.push({
          htmlText: this.convertToHtml(this.botQuestions[newIndex].text),
          sender: 'AI Bot',
          type: 'received'
        })
        this.currentQuestionIndex = newIndex
      } else {
        this.done = true
        const conversation = await chatService.createChat({ title: this.newChat.title })
        const messageToSave = {
          _id: conversation?._id,
          title: this.newChat.question,
          question: this.newChat.question,
          survey: this.newChat.survey._id,
          startDate: this.newChat.date.startDate,
          endDate: this.newChat.date.endDate,
          questionId: this.newChat.surveyQuestion._id,
          metadata: this.newChat.metadata.key,
          query: {
            $and: [
            ]
          }
        }

        await this.sendMessage(messageToSave)
        this.setKey('showNewChat', false)
        this.toggleChatList()
        this.hideChatDefault()
        this.setKey('showChatList', false)
        this.setKey('currentChat', messageToSave)
        this.setKey('chats', [messageToSave].concat(this.intelligence.chats))
      }
      this.showLoading = false
    },
    updateText () {
      if (this.botCurrentQuestion.type === 'text') {
        this.newChat[this.botCurrentQuestion.field] = this.$refs.botInput[0].innerText.trim()
        return
      }
      this.newChat.question = this.$refs.chatInput.innerText.trim()
    },
    async changeSurveyAndSetQuestions () {
      this.surveyQuestions = []
      this.newChat.survey = await this.getSurvey(this.newChat.survey._id)
      this.surveyQuestions.unshift({ question: this.$t('intelligence.chat.inputs.all'), _id: '' })
      this.newChat.survey.questions.forEach(e => { this.surveyQuestions.push(e) })
    },
    changeDate (dates) {
      let startDate
      let endDate
      if (typeof dates === 'string') {
        startDate = dateFormatter.stringPeriodToPlainDate(dates).formatedStart
        endDate = dateFormatter.stringPeriodToPlainDate(dates).formatedEnd
      } else {
        startDate = this.$moment(dates.start, 'YYYY-MM-DD HH:mm ZZ').startOf('day').utc().format()
        endDate = this.$moment(dates.end, 'YYYY-MM-DD HH:mm ZZ').endOf('day').utc().format()
      }

      this.newChat.date = { startDate, endDate }
      this.newChat.startDate = startDate
      this.newChat.endDate = endDate
      this.newChat.convertedDate = `${this.$moment(startDate).format('DD/MM/YYYY')} - ${this.$moment(endDate).format('DD/MM/YYYY')}`
      this.nextQuestion()
    }
  }
}
</script>

<style lang='scss'>
.new-chat-intelligence {
  .container-binds-select {
    flex: 1;

    .selected-option {
      font-size: 1vw !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.new-chat-intelligence {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .new-chat-header {
    display: flex;
    align-items: center;
    gap: 2vw;
    height: 5%;
    padding: 0 10px;
    width: 100%;

    .icon-show-list {
      cursor: pointer;
      font-size: 1.5vw;
      padding: 0.35vw;
      border-radius: 5px;
    }

    .icon-show-list:hover {
      background: #3f335620;
    }
  }

  .question-input {
    width: 100%;
  }

  .chat-messages {
    background-image: url('./images/wallpaper/5.jpg');
    background-size: cover;
    background-position: center;
    padding: 1vw;
    background-color: #e9eef4;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    .message {
      display: flex;
    }

    .message-sent {
      flex-direction: row-reverse;
      .container-message-bot {
        display: flex;
        justify-content: flex-end;
        .message-content {
          width: fit-content;
          max-width: 85%;
          background: #b3d4fc;
          color: #000000;
          border-radius: 15px 0px 15px 15px;
        }
      }
    }

    .message-received {
      flex-wrap: wrap;
      .container-message-bot {
        padding-right: 2.5vw;
      }
      .container-inputs-bot {
        width: 100%;
        .section-input-bot {
          margin-top: 0.5vw;
        }
      }
    }
  }

  .container-date-picker {
    width: 60%;
    height: 3rem;
    display: block;

    .content-date-picker {
      width: 100%;
      height: 100%;

      .section-date-picker {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        border: 0.1rem solid #cacaca;
        font-size: 1rem;
        color: #808080;
        border-radius: 0.3rem;
        gap: 6px;
        background: white;
        align-items: center;
        padding: 10px;

        .start-date,
        .end-date {
          font-size: 1rem;
          display: flex;
          align-items: center;
        }

        .icon-calendar {
          font-size: 1rem;
          color: var(--accent-color);
        }
      }
    }
  }

  .container-message-bot {
    display: flex;
    width: 100%;
    gap: 0.5vw;
    .section-icon-binds-intelligence {
      display: flex;
      justify-content: center;
      .avatar {
        width: 2vw;
        height: 2vw;
        border-radius: 50%;
      }
    }
    .message-content {
      position: relative;
      width: fit-content;
      min-width: 35%;
      padding: 10px 15px;
      background-color: #d4e6ff;
      color: #333;
      font-size: 1.15rem;
      line-height: 1.7em;
    }
  }

  .content-questions-default {
    width: 100%;
    padding: 1vw 0 0.5vw 2.5vw;
    .section-questions-default {
      padding: 0.5vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1vw;
      .title-questions-default {
        font-size: 0.9vw;
        font-weight: 600;
      }
      .question-default {
        background: #b3d4fc;
        padding: 0.5vw 0.8vw;
        border-radius: 0 15px 15px 15px;
        font-weight: 500;
        color: #333;
        font-size: 0.9rem;
        line-height: 1.7em;
      }
    }
  }

  .message-received .message-content {
    border-radius: 0px 15px 15px 15px;
  }

  .message-input {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
    border-radius: 10px;
  }

  .message-input i {
    font-size: 24px;
    color: #4a76a8;
    margin-right: 10px;
    cursor: pointer;
  }

  .chat-input {
    flex: 1;
    padding: 15px;
    min-height: 30px;
    max-height: 70px;
    overflow-y: auto;
    background-color: #f0f2f5;
    border-radius: 20px;
    outline: none;
    font-size: 0.9rem;
    color: #333;
    overflow-y: auto;
    height: auto;
  }

  .binds-selector-chat {
    height: 3.2rem;
    gap: 6px;
    width: 65%;
  }

  .send-icon {
    font-size: 24px;
    color: #4a76a8;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
