
/**
 * Converts a JavaScript object to a JSON string.
 * @param {Object} query - The JavaScript object to convert.
 * @returns {string} - The JSON string representation of the object.
 * @throws {Error} - If an error occurs during JSON stringification.
 */
const objectToString = function (query) {
  try {
    return JSON.stringify(query)
  } catch (err) {
    return query
  }
}

/**
   * Converts a JSON string to a JavaScript object.
   * @param {string} query - The JSON string to convert.
   * @returns {Object} - The JavaScript object representation of the string.
   * @throws {Error} - If an error occurs during JSON parsing.
   */
const stringToObject = function (query) {
  try {
    return JSON.parse(query)
  } catch (err) {
    return query
  }
}

module.exports = {
  stringToObject,
  objectToString
}
