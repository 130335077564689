<template>
  <div style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; z-index: 99; background: transparent;" @click="handleClickOutsideSidebar($event)">
    <div class="account-config">
      <div class="row">
        <div class="my-profile-header">
          <div class="account-button close-config col-lg-12 col-md-12 col-xs-12 col-sm-12 col-xs-12">
            <button @click="showCloseModal = true">
              <app-textstyle color="purple" size="size-xl">×</app-textstyle>
            </button>
          </div>
          <div class="account-heading">
            <app-heading level="h5">{{$t('menu.my_account')}}</app-heading>
          </div>
        </div>
        <Expander :title="$t('account_config.myCompanyInfo')" :showOnStartup="true">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 account-label-btn labelInputConfig">
            <div class="company-name">
              <app-heading level="h6" class="mt20">ID:</app-heading>
              <app-form-input
                class="messageLabel"
                v-model="user.account._id"
                border-bottom
                disabled
              ></app-form-input>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 account-label-btn labelInputConfig">
            <div class="company-name">
              <app-heading level="h6">{{$t('account_config.company_name')}}:</app-heading>
              <app-form-input
                class="messageLabel"
                v-model="user.account.name"
                border-bottom
              ></app-form-input>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 account-label-btn labelInputConfig" v-if="this.user.account.features.tickets">
            <div class="account-from">
              <app-heading level="h6">{{$t('account_config.email_signature')}}:</app-heading>
              <app-form-input
                class="messageLabel"
                v-model="user.account.ticket.emailSignature"
                border-bottom
              ></app-form-input>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 account-label-btn labelInputConfig" v-if="this.user.account.features.tickets">
            <div class="account-from">
              <app-heading level="h6">{{$t('account_config.from')}}:</app-heading>
              <app-form-input
                class="messageLabel"
                v-model="user.account.ticket.fromName"
                border-bottom
              ></app-form-input>
            </div>
          </div>
        </Expander>
        <hr style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">
        <Expander :title="this.$i18n.t('account_config.colors_settings')" v-if="['superadmin', 'admin'].indexOf(user.type) > -1" :showOnStartup="false">
          <div class="row">
            <!-- Colors -->
            <app-heading level="h7" class="colors-config-title">{{$t('account_config.fromSystem')}}</app-heading>
            <div class="row middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group" v-title="$t('account_config.colors.superiorMenu')">
                <app-heading level="h7">
                  1. {{$t('account_config.primaryColor')}}
                  <i class="material-icons">info</i>
                </app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="palette" v-model="user.account.theme.colors.primary" @input="updateColors">
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>
            <div class="row middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group" v-title="$t('account_config.colors.icons')">
                <app-heading level="h7">
                  2. {{$t('account_config.secondaryColor')}}
                  <i class="material-icons">info</i>
                </app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="palette" v-model="user.account.theme.colors.secondary" @input="updateColors">
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>
            <app-heading level="h7" class="colors-config-title">{{$t('account_config.fromSurvey')}}</app-heading>
            <div class="row middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group" v-title="$t('account_config.colors.surveyMenu')">
                <app-heading level="h7">
                  1. {{$t('account_config.primaryColor')}}
                  <i class="material-icons">info</i>
                </app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="palette" v-model="user.account.theme.colors.accent" @input="updateColors">
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>
            <app-heading class="col-lg-12 col-md-12 col-sm-12 col-xs-12 colors-config-title radio" level="h6">{{$t('account_config.text_light.label')}}:</app-heading>
            <app-form-radio-group
              class="roles-options"
              :items="textColorItems"
              :initialValue="user.account.theme.colors.primaryText"
              @valueGroup="valueChanged($event)"
            >
            </app-form-radio-group>
          </div>
        </Expander>
        <hr style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">
        <Expander :title="$t('account_config.configImg')" :showOnStartup="false">
          <div>
            <!-- Colors - END -->
            <!-- Main image -->
            <app-heading class="col-lg-12 col-md-12 col-sm-12 col-xs-12 colors-config-title" level="h6">{{$t('account_config.main_image')}}:</app-heading>
            <SetImg class="col-lg-12 col-md-12 col-sm-12 col-xs-12" :imageProps="imageLogoUrl" :title="'alguma coisa aqui'" context="account"/>
            <!-- Main image - END -->
            <!-- Main logo -->
            <app-heading class="col-lg-12 col-md-12 col-sm-12 col-xs-12 colors-config-title" level="h6">{{$t('account_config.main_logo')}}:</app-heading>
            <SetImg class="col-lg-12 col-md-12 col-sm-12 col-xs-12" :imageProps="imageMainLogo" :title="'alguma coisa aqui'" context="account"/>
            <!-- Main logo - END -->
            <div class="account-blend">
              <!-- <app-toggle-input v-model="user.account.theme.mixBlendMode"></app-toggle-input> -->
              <input type="checkbox" v-model="user.account.theme.mixBlendMode" class="blend-checkbox" />
              <app-heading level="h6">{{ $t('account_config.blend_mode') }}</app-heading>
            </div>
            <!-- Small logo -->
            <app-heading class="col-lg-12 col-md-12 col-sm-12 col-xs-12 colors-config-title" level="h6">{{$t('account_config.small_logo')}}:</app-heading>
            <SetImg class="col-lg-12 col-md-12 col-sm-12 col-xs-12" :imageProps="imageSmallLogo" :title="'alguma coisa aqui'" context="account"/>
            <!-- Small logo - END -->
          </div>
        </Expander>
        <hr style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);" />
      </div>
      <div class="save-btn-container">
        <app-button class="save-account" variation="primary" v-on:click="saveConfig()">{{ $t('profile.save') }}</app-button>
      </div>
      <div class="loadingModal" v-show="isLoading">
        <rotate-square5></rotate-square5>
      </div>
      <div>
      <theme-style>
        .my-profile-header {
          background-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
        }
        .my-profile-header .heading,
        .my-profile-header .close-config button span {
          color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
        input.blend-checkbox {
          accent-color: {{ user.account.theme.colors.accent || '#673ab7'}};
        }
      </theme-style>
      </div>
    </div>
      <app-modal
        v-model="showCloseModal"
        :title="$t('survey.exit_whithout_save')"
        lightbox
        close
        class="modal-default-primary"
        :primary-button="this.$t('account_config.delete_confirm.yes')"
        @primaryButtonClick="canBeClosed = true; handleCloseSidebar()"
        :secondary-button="this.$t('account_config.delete_confirm.no')"
        @secondaryButtonClick="showCloseModal = false, canBeClosed = false"
      >
        <app-heading level="h7">{{ $t('survey.exit_message') }}</app-heading>
      </app-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { accountService } from '@/_services'
import { metadataManipulator } from '@/_helpers'
import SetImg from '../image/SetImg.vue'
import Expander from '../SideBarConfig/Expander.vue'

export default {
  name: 'accountConfig',
  props: ['isActive'],
  components: {
    SetImg,
    Expander,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-form-radio-group': () => import('@binds-tech/binds-design-system/src/components/Form/RadioGroup'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    RotateSquare5: () => import('vue-loading-spinner/src/components/RotateSquare5')
  },
  data () {
    return {
      currentQuestion: '',
      charts: [],
      isLoading: false,
      chart: this.$i18n.t('dashboard.graphics.choose'),
      mainColor: '#673ab7',
      textColor: '#fff',
      colorPalettePreview: [],
      account: {},
      textColorItems: [
        { label: this.$t('account_config.text_light.dark'), type: 'dark', value: false },
        { label: this.$t('account_config.text_light.light'), type: 'light', value: false }
      ],
      canBeClosed: false,
      showCloseModal: false
    }
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.showCloseModal = true
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    imageLogoUrl () {
      const result = {}
      result.logo = this.user.account.logoUrl
      result.where = 'imageLogoUrl'
      result.success = !!this.user.account.logoUrl
      result.hideDelete = true
      result.crop = '800x600 100%'
      result.imagesOnly = true
      return result
    },
    imageMainLogo () {
      const result = {}
      result.logo = this.user.account.theme.mainLogo
      result.where = 'imageMainLogo'
      result.success = !!this.user.account.theme.mainLogo
      result.hideDelete = true
      result.crop = '800x600 100%'
      result.imagesOnly = false
      return result
    },
    imageSmallLogo () {
      const result = {}
      result.logo = this.user.account.theme.smallLogo
      result.where = 'imageSmallLogo'
      result.success = !!this.user.account.theme.smallLogo
      result.hideDelete = true
      result.crop = '800x600 100%'
      result.imagesOnly = false
      return result
    }
  },
  methods: {
    handleCloseSidebar () {
      if (this.canBeClosed && this.showCloseModal) this.$root.$emit('close-account-config')
      else return null
    },
    handleClickOutsideSidebar (event) {
      if (event.target._prevClass === 'expand-profile') this.showCloseModal = true
      else return null
    },
    valueChanged (event) {
      if (event === 'light') this.user.account.theme.colors.primaryText = 'light'
      else this.user.account.theme.colors.primaryText = 'dark'
    },
    updateColors () {
      this.$store.commit('account/setAccount', this.user.account)
    },
    async saveConfig () {
      this.isLoading = true
      const accountToSave = metadataManipulator.removeFieldsFromAccounts(this.user.account)
      const result = await accountService.updateAccount(this.user.account._id, accountToSave)
      if (result._id) {
        this.$store.commit('account/setAccount', result)
      }
      this.$root.$emit('close-account-config')
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.modal-default-primary .modal__wrapper__close-icon .close {
  color: var(--accent-color) !important;
}
.account-config {
  min-height: 100%;
  z-index: 9999;
  width: 28rem;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 10px 10px 5px 10px rgba(48, 48, 48, 0.5);
  overflow: auto;

  .expander {
    padding: 15px;
    width: 100%;
    > div.row.pointer {
    justify-content: space-between;
    }
  }
}
.account-button {
  top: -20px;
  right: 0;
  position: absolute;
}
.account-heading {
  & .heading {
    padding: 15px !important;
  }
}
.roles-options {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .input-wrapper {
    .label {
      margin: 0;
      .input:checked ~ .checkmark {
        border: 4px solid;
      }
      .checkmark {
        margin-top: 6px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .input-wrapper::after {
    display: none;
  }
  .content-radio-group {
    margin: 10px;
  }
}
.account-blend {
  display: flex;
  padding-top: 10px;
  margin-top: 15px;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 20px !important;
    line-height: 20px !important;
  }
  > input {
    height: 17px;
    width: 17px;
  }
}
.messageLabel {
  & .text-field__input {
    input.user-click {
      margin-top: 15px;
      background: #D9D9D933;
    }
  }
}
.account-label-btn {
  & h6 {
    font-size: 14px !important;
    margin-left: 0 !important;
  }
  & .text-field__input {
    input {
      font-size: 14px !important;
    }
  }
}
div.color-group  {
  p {
    display: flex;
    align-items: center
  }
  .material-icons {
    padding: 3px 0 0 5px;
    font-size: 1rem;
  }
}

div.color-box {
  width: 76px;
  padding: 2px 5px;
  input.palette {
    margin: 2px 0;
  }
}
.colors-config-title {
  margin-top: 20px !important;
  font-size: 14px !important;

  &.radio {
    text-align: center;
  }
}
.main-colors {
  display: flex;
  padding: 13px 0px !important;
}
.select-main-color {
  margin-right: 10px;
}
.text-color-input {
  margin-left: -15px !important;
}
.save-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  button.save-account {
    font-size: 14px !important;
    margin: 25px 0;
    padding: 9px 10px;
    font-size: 16px;
    width: 230px;
    background: var(--accent-color);
    box-shadow: var(--secondary-color) !important;
    &.hover,
    &:hover {
      background: var(--secondary-color) !important;
      box-shadow: var(--secondary-color) !important;
    }
  }
}
</style>
